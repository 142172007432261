import axiosInstance from "./AxiosInstance";

export function getAppSetting(name) {
  return axiosInstance
    .get(`v1/app/appsetting?name=${name}`)
    .then((res) => res.data);
}

export function updateAppSetting(id, body) {
  return axiosInstance
    .patch(`v1/app/appsetting/${id}`, body)
    .then((res) => res.data);
}
