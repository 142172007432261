import React, { useMemo, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { Modal } from "react-bootstrap";
import ReactSelect from "react-select";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { GlobalFilter } from "../components/table/FilteringTable/GlobalFilter";
//import './table.css';
import "../components/table/FilteringTable/filtering.css";
import { ColumnFilter } from "../components/table/FilteringTable/ColumnFilter";
import { Link } from "react-router-dom";
import { getApplicationUsers } from "../../services/UserService";
import swal from "sweetalert";
import EditableAssesment from "./EditableAssesment";
import { getAssesmentsTemplates } from "../../services/AssesmentTemplateService";
import {
  addAssignment,
  deleteAssignment,
  editAssignment,
  getAssignment,
} from "../../services/AssigmentService";
export const Assesment = () => {
  const [users, setUsers] = useState([]);
  const [assesmentTemplate, setAssesmentTemplate] = useState([]);
  const [assesment, setAssesments] = useState([]);
  const COLUMNS = [
    {
      Header: "User",
      Footer: "name",
      accessor: "assesmentUser",
      Filter: ColumnFilter,
      Cell: ({ value }) => {
        if (value) return value?.name;
        else return "";
      },
    },
    {
      Header: "Template",
      Footer: "Country Agency",
      accessor: "assessmentTemplate",
      Cell: ({ value }) => {
        if (value) return value?.name;
        else return "";
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Notes",
      Footer: "notes",
      accessor: "notes",
      Filter: ColumnFilter,
    },
    {
      Header: "action",
      Footer: "action",
      accessor: "action",
      Cell: (data) => {
        return (
          <div className="d-flex">
            <Link
              className="btn btn-secondary	 shadow btn-xs sharp me-2"
              onClick={(event) => {
                handleEditClick(
                  event,
                  data.row.original,
                  users,
                  assesmentTemplate
                );
              }}
              to="#"
            >
              <i className="fas fa-pen"></i>
            </Link>
            <Link
              className="btn btn-danger shadow btn-xs sharp"
              onClick={() => handleDeleteClick(data.row.original._id)}
              to="#"
            >
              <i className="fa fa-trash"></i>
            </Link>
          </div>
        );
      },
      Filter: ColumnFilter,
    },
  ];
  const columns = useMemo(() => COLUMNS, [users, assesmentTemplate]);

  const tableInstance = useTable(
    {
      columns,
      data: assesment,
      initialState: { pageIndex: 0, pageSize: 50000 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;
  // delete data
  const handleDeleteClick = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Assesment?!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteAssignment(id)
          .then(() => {
            getAllUsers();
            swal("Poof! Your Assesment has been deleted!", {
              icon: "success",
            });
          })
          .catch(() => {
            swal("Something went Wrong..!", {
              icon: "error",
            });
          });
      } else {
        swal("Your Assesment is safe!");
      }
    });
  };

  //Modal box
  const [addCard, setAddCard] = useState(false);
  //Add data
  const [addFormData, setAddFormData] = useState({
    assesmentUser: "",
    assessmentTemplate: "",
    notes: "",
  });

  // Add contact function
  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
  };

  const handleSelectChange = (field, value) => {
    const newFormData = { ...addFormData };
    newFormData[field] = value;
    setAddFormData(newFormData);
  };

  //Add Submit data
  const handleAddFormSubmit = async (event) => {
    event.preventDefault();
    var error = false;
    var errorMsg = "";
    if (addFormData.assesmentUser === "") {
      error = true;
      errorMsg = "Please Select the User";
    } else if (addFormData.assessmentTemplate === "") {
      error = true;
      errorMsg = "Please the Template";
    }

    if (!error) {
      const newContent = {
        assesmentUser: addFormData.assesmentUser.id,
        assessmentTemplate: addFormData.assessmentTemplate._id,
        notes: addFormData.notes,
      };

      await addAssignment(newContent)
        .then(() => {
          getAssignment().then((res) => setAssesments(res));
          setAddCard(false);
          swal("Assesment Assigned successfully!", {
            icon: "success",
          });
          setAddCard(false);
          setAddFormData({
            assesmentUser: "",
            assessmentTemplate: "",
            notes: "",
          });
        })
        .catch((err) => {
          swal(err.response.data.message, { icon: "error" });
        });
    } else {
      swal("Oops", errorMsg, "error");
    }
  };

  //Edit start
  //const [editModal, setEditModal] = useState(false);
  // Edit function editable page loop
  const [editContentId, setEditContentId] = useState(null);

  // Edit function button click to edit
  const handleEditClick = (event, content, users, assesmentTemplate) => {
    event.preventDefault();
    setEditContentId(content._id);
    const user = users.find((user) => user.id === content.assesmentUser.id);
    const template = assesmentTemplate.find(
      (template) => template._id === content.assessmentTemplate._id
    );
    const formValues = {
      assesmentUser: user,
      assessmentTemplate: template,
      notes: content.notes,
    };
    setEditFormData(formValues);
  };

  // edit  data
  const [editFormData, setEditFormData] = useState({
    assesmentUser: "",
    assessmentTemplate: "",
    notes: "",
  });

  //update data function
  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  const handleEditSelectChange = (field, value) => {
    const newFormData = { ...editFormData };
    newFormData[field] = value;
    setEditFormData(newFormData);
  };

  // edit form data submit
  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const editedContent = {
      id: editContentId,
      assesmentUser: editFormData.assesmentUser.id,
      assessmentTemplate: editFormData.assessmentTemplate._id,
      notes: editFormData.notes,
    };
    editAssignment(editedContent.id, editedContent)
      .then(() => {
        getAllUsers();
        swal("Good job!", "Successfully Updated", "success");
        setEditContentId(null);
        setEditFormData({
          assesmentUser: "",
          assessmentTemplate: "",
          notes: "",
        });
      })
      .catch((err) => {
        swal(err.response.data.message, { icon: "error" });
      });
  };

  //Cencel button to same data
  const handleCancelClick = () => {
    setEditContentId(null);
  };

  const getAllUsers = () => {
    getApplicationUsers().then((res) => setUsers(res.results));
    getAssignment().then((res) => setAssesments(res));
    getAssesmentsTemplates().then((res) => setAssesmentTemplate(res));
  };

  React.useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <>
      <PageTitle activeMenu="All Assigned Assesment" motherMenu="Assesment" />
      <Modal className="modal fade" show={addCard} onHide={setAddCard}>
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Assign Assesment</h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setAddCard(false)}
                  data-dismiss="modal"
                >
                  <span></span>
                </button>
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">User</label>
                      <div className="contact-name">
                        <ReactSelect
                          name="agency"
                          fieldName="agency"
                          onChange={(e) =>
                            handleSelectChange("assesmentUser", e)
                          }
                          options={users}
                          value={addFormData.assesmentUser}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          style={{
                            lineHeight: "40px",
                            color: "#7e7e7e",
                            paddingLeft: " 15px",
                          }}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Template</label>
                      <div className="contact-name">
                        <ReactSelect
                          name="agency"
                          fieldName="agency"
                          onChange={(e) =>
                            handleSelectChange("assessmentTemplate", e)
                          }
                          options={assesmentTemplate}
                          value={addFormData.assessmentTemplate}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          style={{
                            lineHeight: "40px",
                            color: "#7e7e7e",
                            paddingLeft: " 15px",
                          }}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Notes</label>
                      <div className="contact-name">
                        <textarea
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="notes"
                          rows={4}
                          required="required"
                          onChange={handleAddFormChange}
                          placeholder="Enter your Notes"
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleAddFormSubmit}
                >
                  Add
                </button>
                <button
                  type="button"
                  onClick={() => setAddCard(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Assesment Datatable</h4>
          <button className="btn btn-primary" onClick={() => setAddCard(true)}>
            <i className="fa fa-plus" /> Assign New Assesment
          </button>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            {assesment && assesment.length > 0 && (
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            )}
            <form onSubmit={handleEditFormSubmit}>
              {assesment && assesment.length > 0 ? (
                <table {...getTableProps()} className="table  display">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                            {column.canFilter ? column.render("Filter") : null}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()} className="">
                    {page.map((row) => {
                      prepareRow(row);
                      if (editContentId === row.original._id) {
                        return (
                          <EditableAssesment
                            key={row.original.id}
                            users={users}
                            assesmentTemplate={assesmentTemplate}
                            editFormData={editFormData}
                            handleEditFormChange={handleEditFormChange}
                            handleCancelClick={handleCancelClick}
                            handleEditSelectChange={handleEditSelectChange}
                          />
                        );
                      } else {
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {" "}
                                  {cell.render("Cell")}{" "}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              ) : (
                <div className="text-center">No Data Found</div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Assesment;
