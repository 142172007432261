import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import RiskMatrix from "./RiskMatrix";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import UserInfo from "./UserInfo";
import AssesmentAnswer from "./AssesmentAnswer";
import Swal from "sweetalert2";
import { getAssesmentById } from "../../services/AssesmentService";
import { useSelector } from "react-redux";
import * as FileSaver from "file-saver";
import NotesSummary from "./NotesSummary";
import * as XLSX from "xlsx";
import moment from "moment";
export default function AssesmentSummary(props) {
  const { assesmentId } = useParams();
  const location = useLocation();
  const user = useSelector((state) => state.auth.auth);
  const [assesment, setAssesment] = React.useState(undefined);

  const [downloadingPDF, setDownloadingPDF] = React.useState(false);
  const [downloadingExcel, setDownloadingExcel] = React.useState(false);

  const [loaded, setLoaded] = React.useState(false);
  const [scope, setScopeData] = React.useState({
    Assesor: "",
    SuperVisor: undefined,
    Agency: "",
    Type: { label: "Event", value: "Event" },
    Description: "",
    AssesmentDate: "",
    Country: { label: "United States", value: "United States" },
    City: "",
    Address: "",
    Notes: "",
  });
  const [threat, setThreatData] = React.useState([
    {
      id: 1,
      ThreatName: "Threat 1",
      Category: { value: "Armed conflict", label: "Armed conflict" },
      Type: {
        value: "Abduction/hostage taking",
        label: "Abduction/hostage taking",
      },
      Description: "",
      performanceIndex: {
        Capability: "1",
        History: "1",
        Intention: "1",
      },
      ControlMeasure: {
        detail: "",
        impact: [],
        notes: "",
      },
      Impact: {
        People: "1",
        Information: "1",
        Property: "1",
        Capability: "1",
        Economic: "1",
        Reputation: "1",
        notes: "",
      },
      Likelihood: {
        chance: "1",
        notes: "",
      },
      RiskRegister: [
        {
          Strategy: { value: "Physical Security", label: "Physical Security" },
          Category: {
            value: "Perimeters and Barriers",
            label: "Perimeters and Barriers",
          },
          Feature: {
            value: "Perimeter Fencing",
            label: "Perimeter Fencing",
            Category: "Perimeters and Barriers",
          },
          Control_Measure_Details: "",
          Responsible_person: "",
          Deadline: "",
          Date_completed: "",
        },
      ],
    },
  ]);
  const [vulnerabilities, setVulnerabilitiesData] = React.useState({
    Experience: "1",
    Training: "1",
    Awareness: "1",
    Planning: "1",
    Security_Culture: "1",
    Incident_Management: "1",
    Acceptance: "1",
    Physical_Protection: "1",
    notes: "",
  });
  React.useEffect(async () => {
    if (assesmentId && assesmentId !== "undefined") {
      const data = await getAssesmentById(assesmentId);
      if (data) {
        const parsedData = JSON.parse(data.assessmentResponse);
        setAssesment(data);
        setScopeData(parsedData.scope);
        setThreatData(parsedData.threat);
        setVulnerabilitiesData(parsedData.vulnerabilities);
      }
      setLoaded(true);
    } else if (assesmentId === "undefined") {
      let data = location.state.assesment;
      const parsedData = JSON.parse(data.assessmentResponse);
      data.assesmentUser = { name: user.displayName, email: user.email };
      setAssesment(data);
      setScopeData(parsedData.scope);
      setThreatData(parsedData.threat);
      setVulnerabilitiesData(parsedData.vulnerabilities);
    }
  }, [assesmentId]);

  const exportPdf = () => {
    setDownloadingPDF(true);
    const input = document.getElementById("capture");
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;
    const pdf = new jsPDF("l", "pt", "a4");
    let width = pdf.internal.pageSize.width;
    let height = pdf.internal.pageSize.height;
    let pageHeightInPixels = inputHeight;
    let pages = pageHeightInPixels / height;

    const roundOff = Number(pages.toString().split(".")[1].substring(0, 1));
    const pageNo = roundOff > 0 ? pages + 1 : pages;
    let pageCount = pages < 1 ? 1 : Math.trunc(pageNo);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // origin
      for (let i = 1; i <= pageCount; i++) {
        let pdfStartingHeight = height * (i - 1);
        pdf.addImage(imgData, "PNG", 0, -pdfStartingHeight, width, inputHeight);
        if (i < pageCount) {
          pdf.addPage();
        }
      }
      setDownloadingPDF(false);
      const fileName =
        `SRA-${scope?.Agency}-${scope?.Country?.label.replaceAll(
          " ",
          "-"
        )}-${scope?.Type?.label?.replaceAll(" ", "-")}` +
        moment(new Date()).format("DD-MM-YYYY");
      pdf.save(`${fileName}.pdf`);
    });
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportAsExcel = () => {
    Swal.fire({
      title: "Export as Excel",
      text: "Are you sure you want to export as excel?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Export it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result) {
        setDownloadingExcel(true);
        const csvdata = [];
        for (let i = 0; i < threat.length; i++) {
          const data = threat[i];
          for (let j = 0; j < data.RiskRegister.length; j++) {
            let measure = data.RiskRegister[j];
            const item = {
              "Threat Name": data.ThreatName,
              Strategy: measure.Strategy,
              Category: measure.Category,
              Feature: measure.Feature,
              "Control Measure Details": measure.Control_Measure_Details,
              "Responsible person": measure.Responsible_person,
              Deadline: measure.Deadline,
              "Date completed": measure.Date_completed,
            };
            csvdata.push(item);
          }
        }
        const ws = XLSX.utils.json_to_sheet(csvdata);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        setDownloadingExcel(false);
        const fileName =
          `RR-SRA-${scope?.Agency}-${scope?.Country?.label.replaceAll(
            " ",
            "-"
          )}-${scope?.Type?.label?.replaceAll(" ", "-")}` +
          moment(new Date()).format("DD-MM-YYYY");
        FileSaver.saveAs(data, `${fileName}${fileExtension}`);
      }
    });
  };
  return (
    <>
      <Row>
        <Col xs={6}>
          <h4>Assesment Id: # {assesmentId} </h4>
        </Col>
        <Col xs={6} style={{ textAlign: "right" }}>
          <Button onClick={exportPdf}>
            Download as PDF{"  "}
            {downloadingPDF && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>{" "}
          &nbsp;
          <Button onClick={exportAsExcel}>
            Download as Excel
            {downloadingExcel && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>
        </Col>
      </Row>
      <div id="capture">
        <br /> <br />
        {assesment && <UserInfo assesment={assesment} />}
        <Card>
          <Card.Header>
            <Card.Title>Risk Matrix</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col xl={12}>
                <RiskMatrix threadData={threat} disabled={true} />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {assesment && (
          <AssesmentAnswer
            scope={scope}
            threadData={threat}
            vulnerabilities={vulnerabilities}
          />
        )}
        {assesment && (
          <Card>
            <Card.Header>
              <Card.Title>Notes</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xl={12}>
                  <NotesSummary
                    scope={scope}
                    vulnerabilities={vulnerabilities}
                    threadData={threat}
                    disabled={true}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  );
}
