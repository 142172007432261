import React from 'react';
import { getNotesData } from '../../utils/completedUtils';


export default function NotesSummary({ threadData, scope, vulnerabilities }) {
  const [threat, setThreat] = React.useState([]);
  React.useEffect(() => {
    if (threadData && threadData.length > 0) {
      const threat = getNotesData(threadData);
      setThreat(threat);
    }
  }, [threadData]);
  console.log(vulnerabilities);
  return (
    <table className="min-w-full w-full table table-bordered table-striped user_detail">
      <tbody>
        <tr>
          <th>Threat</th>
          <th>Section</th>
          <th>Notes</th>
        </tr>
        {scope.Notes && scope.Notes != '' && (
          <tr>
            <td></td>
            <td>Scope</td>
            <td>{scope.Notes}</td>
          </tr>
        )}
        {vulnerabilities.notes && vulnerabilities.notes !== '' && (
          <tr>
            <td></td>
            <td>Vulnerability</td>
            <td>{vulnerabilities.notes}</td>
          </tr>
        )}
        {threat &&
          threat.map((data, i) => (
            <>
              <tr>
                <td>{data.threat}</td>
                <td>{data.section}</td>
                <td>{data.notes}</td>
              </tr>
            </>
          ))}
      </tbody>
    </table>
  );
}
