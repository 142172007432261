import React from "react";
const category = [
  "Armed conflict",
  "Terrorism",
  "Crime",
  "Civil unrest",
  "Hazard/Natural Disaster",
  "Other",
];
const threatType = [
  "Abduction/hostage taking",
  "Armed incident – AKDN / Jamat targeted",
  "Armed incident – incidental",
  "Arrest/detention",
  "Arson",
  "Assault",
  "Burglary",
  "Facility intrusion",
  "Harassment/intimidation/threat of violence",
  "Hostile surveillance or reconnaissance",
  "Missing person",
  "Movement restriction",
  "Public gathering – non-violent",
  "Public gathering – violent",
  "Robbery",
  "Seizure",
  "Sexual assault",
  "Theft",
  "Vandalism",
  "Accident – aircraft",
  "Accident – fire",
  "Accident – other transportation",
  "Accident – vehicular",
  "Accidents – other",
  "Earthquake",
  "Flood",
  "Landslide/mudslide/avalanche",
  "Destructive weather system",
  "Tsunami",
  "Volcanic eruption",
  "Industrial disaster",
  "Explosive remnant of war",
  "Medical Epidemic",
  "Medical Emergency",
  "Other",
];
export default function ThreatSummary({
  threat,
  removeThreat,
  addNewThreat,
  updateThreat,
  updateThreatperformanceIndex,
  disabled,
}) {
  if (!threat) {
    return <></>;
  }
  const getTotal = (item) => {
    const { performanceIndex } = item;
    return (
      parseInt(performanceIndex.Capability) +
      parseInt(performanceIndex.History) +
      parseInt(performanceIndex.Intention)
    );
  };
  return (
    <>
      <div className="col-xl-12 col-lg-12">
        {!disabled && (
          <button className="btn btn-primary ml-25" onClick={addNewThreat}>
            <i className="la la-plus" /> &nbsp; Add New Threat
          </button>
        )}
      </div>
      <div className="col-xl-12 col-lg-12">
        <div className="row">
          <div className="form-group mb-3 col-md-12">
            <table className="min-w-full w-full colored-table user_detail">
              <tbody>
                <tr>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    Threat Name
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    Category
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    Capability
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    History
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    Intention
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    Total
                  </th>
                </tr>
                {threat.map((item, index) => (
                  <tr
                    key={`Threat_summary_New_${index}`}
                    className="border-b border-r"
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                      {item.ThreatName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                      {item.Type.value}
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                      {item.Category.value}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                      {item.performanceIndex.Capability}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                      {item.performanceIndex.History}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                      {item.performanceIndex.Intention}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                      {getTotal(item)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <br /> <br />
            <h4> Threat Description </h4>
            <table className="min-w-full w-full colored-table user_detail">
              <thead className="border-b"></thead>
              <tbody>
                <tr>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    Threat Name
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    Description
                  </th>
                </tr>
                {threat.map((item, index) => (
                  <tr
                    key={`Threat_summary_${index}`}
                    className="border-b border-r"
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                      {item.ThreatName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                      {item.Type.value}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                      {item.Description}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
