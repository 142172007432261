import React from "react";
import { Badge, Col, Row } from "react-bootstrap";
import data from "./alertData";

export default function RiskMatrix({ threadData, vulnerabilities }) {
  const [threat, setThreat] = React.useState(undefined);
  const getThreatScore = (threat) => {
    let threatScore = 0;
    if (!threat && !threat.performanceIndex) {
      return 0;
    }
    Object.keys(threat.performanceIndex).forEach((key) => {
      if (!Number.isNaN(parseInt(threat.performanceIndex[key]))) {
        threatScore += parseInt(threat.performanceIndex[key]);
      }
    });
    return threatScore;
  };
  const getTotalVulmerabilityScore = () => {
    let score = 0;
    if (!vulnerabilities) {
      return 0;
    }
    Object.keys(vulnerabilities).forEach((key) => {
      if (!Number.isNaN(parseInt(vulnerabilities[key])) && key !== "Total") {
        score += parseInt(vulnerabilities[key]);
      }
    });
    return score;
  };
  const getTotalScore = (Impact) => {
    if (Impact === undefined) {
      return 0;
    }
    let score = 0;
    Object.keys(Impact).forEach((key) => {
      if (!Number.isNaN(parseInt(Impact[key]))) {
        score += parseInt(Impact[key]);
      }
    });
    return score;
  };
  React.useEffect(() => {
    if (threadData && threadData !== threat) {
      setThreat(threadData);
    }
  }, [threadData]);

  const getThreatPlacement = (impact, likelihood, type) => {
    if (!threat) {
      return "";
    }
    let updatedThreat = [];
    let Threat = "";
    for (let i = 0; i < threat.length; i++) {
      let items = threat[i];
      if (
        getTotalScore(items.Impact) > impact.min &&
        getTotalScore(items.Impact) <= impact.max &&
        parseInt(items.Likelihood.chance) === likelihood
      ) {
        items.risk_matrix = type;
        Threat = Threat + "<br/>" + items.ThreatName;
      }
    }
    return Threat;
  };

  const getPerformanceVulText = () => {
    const score = getTotalVulmerabilityScore();
    if (score <= 8) {
      return "Very Low";
    } else if (score > 8 && score <= 16) {
      return "Low";
    } else if (score > 16 && score <= 24) {
      return "Medium";
    } else if (score > 24 && score <= 32) {
      return "High";
    } else {
      return "Very High";
    }
  };

  const getVulnerabilityClass = () => {
    const score = getTotalVulmerabilityScore();
    if (score <= 8) {
      return "bg-green-400 color-white";
    } else if (score > 8 && score <= 16) {
      return "bg-blue-400 color-white";
    } else if (score > 16 && score <= 24) {
      return "bg-yellow-400 color-white";
    } else if (score > 24 && score <= 32) {
      return "bg-orange-400 color-white";
    } else {
      return "bg-red-400 color-white";
    }
  };

  const getLikelikeHoodClass = (score) => {
    if (Number.isNaN(score)) {
      return "";
    }
    if (score === 1) {
      return "bg-green-400 color-white";
    } else if (score === 2) {
      return "bg-blue-400 color-white";
    } else if (score === 3) {
      return "bg-yellow-400 color-white";
    } else if (score === 4) {
      return "bg-orange-400 color-white";
    } else {
      return "bg-red-400 color-white";
    }
  };

  const getLikelikeHoodText = (score) => {
    if (Number.isNaN(score)) {
      return "-";
    }
    if (score === 1) {
      return "Very UnLikely";
    } else if (score === 2) {
      return "UnLikely";
    } else if (score === 3) {
      return "Possible";
    } else if (score === 4) {
      return "Likely";
    } else {
      return "Very Likely";
    }
  };

  const getPerformanceText = (Impact) => {
    const score = getTotalScore(Impact);
    if (score <= 6) {
      return "NEGLIGIBLE";
    } else if (score === 7) {
      return "MINOR";
    } else if (score === 8) {
      return "MODERATE";
    } else if (score > 8 && score <= 12) {
      return "SEVERE";
    } else {
      return "Very CRITICAL";
    }
  };

  const getPerformanceClass = (Impact) => {
    const score = getTotalScore(Impact);
    if (score <= 6) {
      return "bg-green-400 color-white";
    } else if (score === 7) {
      return "bg-blue-400 color-white";
    } else if (score === 8) {
      return "bg-yellow-400 color-white";
    } else if (score > 8 && score <= 12) {
      return "bg-orange-400 color-white";
    } else {
      return "bg-red-400 color-white";
    }
  };

  const getRiskClass = (risk) => {
    if (risk === "Very High") {
      return "bg-red-400 color-white";
    } else if (risk === "High") {
      return "bg-orange-400 color-white";
    } else if (risk === "Medium") {
      return "bg-yellow-400 color-white";
    } else if (risk === "Low") {
      return "bg-blue-400 color-white";
    } else {
      return "bg-green-400 color-white";
    }
  };
  const getThreatClass = (score) => {
    if (score <= 3) {
      return "bg-green-400 color-white";
    } else if (score > 3 && score <= 6) {
      return "bg-blue-400 color-white";
    } else if (score > 6 && score <= 9) {
      return "bg-yellow-400 color-white";
    } else if (score > 9 && score <= 12) {
      return "bg-orange-400 color-white";
    } else {
      return "bg-red-400 color-white";
    }
  };
  return (
    <Row>
      <Col xs={12}>
        {threat && (
          <table className="min-w-full table w-full text-center colored-table">
            <thead className="border-b">
              <tr>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4"
                ></th>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4"
                >
                  1 <br /> Negligible
                </th>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4"
                >
                  2 <br /> Minor
                </th>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4"
                >
                  3 <br /> Moderate
                </th>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4"
                >
                  4 <br /> Severe
                </th>
                <th
                  scope="col"
                  className="text-sm font-medium text-gray-900 px-6 py-4"
                >
                  5 <br /> Critical
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b">
                <td className="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                  Very likely
                </td>
                <td
                  className="text-sm text-gray-900  bg-blue-400  font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement({ min: 0, max: 6 }, 1, "Low"),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-yellow-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement({ min: 6, max: 7 }, 1, "Medium"),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-orange-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement({ min: 7, max: 8 }, 1, "High"),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-red-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement(
                      { min: 8, max: 12 },
                      1,
                      "Very High"
                    ),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-red-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement(
                      { min: 12, max: 200 },
                      1,
                      "Very High"
                    ),
                  }}
                ></td>
              </tr>
              <tr className="border-b ">
                <td className="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                  Likely
                </td>
                <td
                  className="text-sm text-gray-900 bg-blue-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement({ min: 0, max: 6 }, 2, "Low"),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-yellow-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement({ min: 6, max: 7 }, 2, "Medium"),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-orange-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement({ min: 7, max: 8 }, 2, "High"),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-orange-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement({ min: 8, max: 12 }, 2, "High"),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-red-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement(
                      { min: 12, max: 200 },
                      2,
                      "Very High"
                    ),
                  }}
                ></td>
              </tr>
              <tr className="border-b  ">
                <td className="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                  Possible
                </td>
                <td
                  className="text-sm text-gray-900 bg-green-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement(
                      { min: 0, max: 6 },
                      3,
                      "Very Low"
                    ),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-blue-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement({ min: 6, max: 7 }, 3, "Low"),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-yellow-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement({ min: 7, max: 8 }, 3, "Medium"),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-orange-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement({ min: 8, max: 12 }, 3, "High"),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-orange-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement(
                      { min: 12, max: 200 },
                      3,
                      "High"
                    ),
                  }}
                ></td>
              </tr>
              <tr className="border-b ">
                <td className="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                  Unlikely
                </td>
                <td
                  className="text-sm text-gray-900 bg-green-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement(
                      { min: 0, max: 6 },
                      4,
                      "Very Low"
                    ),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-blue-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement({ min: 6, max: 7 }, 4, "Low"),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-blue-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement({ min: 7, max: 8 }, 4, "Low"),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-yellow-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement(
                      { min: 8, max: 12 },
                      4,
                      "Medium"
                    ),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-yellow-400 font-light px-6 py-4 whitespace-nowrap color-white "
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement(
                      { min: 12, max: 200 },
                      4,
                      "Medium"
                    ),
                  }}
                ></td>
              </tr>
              <tr className="border-b  ">
                <td className="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                  Very unlikely
                </td>
                <td
                  className="text-sm text-gray-900 bg-green-400 font-light px-6 py-4 whitespace-nowrap color-white"
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement(
                      { min: 0, max: 6 },
                      5,
                      "Very Low"
                    ),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-green-400 font-light px-6 py-4 whitespace-nowrap color-white"
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement(
                      { min: 6, max: 7 },
                      5,
                      "Very Low"
                    ),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-green-400 font-light px-6 py-4 whitespace-nowrap color-white"
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement(
                      { min: 7, max: 8 },
                      5,
                      "Very Low"
                    ),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-blue-400 font-light px-6 py-4 whitespace-nowrap color-white"
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement({ min: 8, max: 12 }, 5, "Low"),
                  }}
                ></td>
                <td
                  className="text-sm text-gray-900 bg-blue-400 font-light px-6 py-4 whitespace-nowrap color-white"
                  dangerouslySetInnerHTML={{
                    __html: getThreatPlacement({ min: 12, max: 200 }, 5, "Low"),
                  }}
                ></td>
              </tr>
            </tbody>
          </table>
        )}
      </Col>
      <Col xs={12} className="mt-3">
        <span className="badge bg-red-400 text-white badge-sm float-end m-4">
          Very High
        </span>
        <span className="badge bg-orange-400 text-white badge-sm float-end m-4">
          High
        </span>
        <span className="badge bg-yellow-400 text-white badge-sm float-end m-4">
          Medium
        </span>
        <span className="badge bg-blue-400 text-white badge-sm float-end m-4">
          Low
        </span>
        <span className="badge text-white badge-sm float-end bg-green-400 m-4">
          Very Low
        </span>
      </Col>
      <Col xs={12} className="mt-3">
        <h3>Summary</h3>
      </Col>
      <Col xs={12}>
        <table className="min-w-full w-full table text-center colored-table">
          <thead className="border-b">
            <tr>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4"
              >
                Event Description
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4"
              >
                <br /> Type
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4"
              >
                <br /> Category
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4"
              >
                <br /> Threat
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4"
              >
                <br /> Vulnerability
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4"
              >
                <br /> Likelihood
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4"
              >
                <br /> Impact
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4"
              >
                <br /> Risk
              </th>
            </tr>
          </thead>
          <tbody>
            {threat &&
              threat.map((item, index) => (
                <tr key={`risk_matrix_summary_${index}`} className="border-b">
                  <td className="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                    {item.ThreatName}
                  </td>
                  <td className="text-sm text-gray-900  font-light px-6 py-4 whitespace-nowrap">
                    {item.Type.value}
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    {item.Category.value}
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    <Badge
                      variant="primary badge-xs light"
                      className={getThreatClass(parseInt(getThreatScore(item)))}
                    >
                      {getThreatScore(item)}
                    </Badge>
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    <Badge
                      variant="primary badge-xs light"
                      className={getVulnerabilityClass()}
                    >
                      {getPerformanceVulText()}
                    </Badge>
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    <Badge
                      variant="primary badge-xs light"
                      className={getLikelikeHoodClass(
                        parseInt(item.Likelihood.chance)
                      )}
                    >
                      {getLikelikeHoodText(parseInt(item.Likelihood.chance))}
                    </Badge>
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    <Badge
                      variant="primary badge-xs light"
                      className={getPerformanceClass(item.Impact)}
                    >
                      {getPerformanceText(item.Impact)}
                    </Badge>
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    {item.risk_matrix ? (
                      <Badge
                        variant="primary badge-xs light"
                        className={getRiskClass(item.risk_matrix)}
                      >
                        {item.risk_matrix}
                      </Badge>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Col>
    </Row>
  );
}
