import React from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import { getAssesmentById } from "../../services/AssesmentService";
import { getScore, getScoreFromAnswer, getTotalScore } from "../../utils";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Spinner from "react-bootstrap/Spinner";

export default function Asssesment2Summary(props) {
  const { location } = props;
  const { assesmentId } = useParams();

  const [downloadingPDF, setDownloadingPDF] = React.useState(false);
  const [downloadingPDFImage, setDownloadingPDFImage] = React.useState(false);
  const user = useSelector((state) => state.auth.auth);
  const [loaded, setLoaded] = React.useState(false);
  const [assesment, setAssesment] = React.useState(undefined);
  const [assesmentResponse, setAssesmentResponse] = React.useState(undefined);
  const [overallScore, setOverallScore] = React.useState(undefined);
  const [safetyResponseScore, setSafetyResponseScore] =
    React.useState(undefined);
  const [locationSecurityScore, setLocationSecurityScore] =
    React.useState(undefined);
  const [acceptanceOutreachScore, setAcceptanceOutreachScore] =
    React.useState(undefined);
  const [perimeterProtectionScore, setPerimeterProtectionScore] =
    React.useState(undefined);
  const [accessControlScore, setAccessControlScore] = React.useState(undefined);
  const [electronicSecurityScore, setElectronicSecurityScore] =
    React.useState(undefined);
  const [securityGuardingScore, setSecurityGuardingScore] =
    React.useState(undefined);
  const [locakdownScore, setLocakdownScore] = React.useState(undefined);
  const [jamatkhanaSecurityScore, setJamatkhanaSecurityScore] =
    React.useState(undefined);
  const [
    StructuralResistanceAndProtectionScore,
    setStructuralResistanceAndProtectionScore,
  ] = React.useState(undefined);

  React.useEffect(async () => {
    if (assesmentId && assesmentId !== "undefined") {
      const data = await getAssesmentById(assesmentId);
      if (data) {
        const parsedData = JSON.parse(data.assessmentResponse);
        setAssesment(data);
        setAssesmentResponse(parsedData);
      }
    } else if (assesmentId === "undefined") {
      const data = location.state.assesment;
      const parsedData = JSON.parse(data.assessmentResponse);
      data.assesmentUser = { name: user.displayName, email: user.email };
      setAssesment(data);
      setAssesmentResponse(parsedData);
    }
    setLoaded(true);
  }, [assesmentId]);

  React.useEffect(() => {
    if (assesmentResponse) {
      const data = getTotalScore(assesmentResponse);
      setOverallScore(data);
      const locationSecurityScore = getScore(
        assesmentResponse.locationSecurity
      );
      const acceptanceOutreachScore = getScore(
        assesmentResponse.acceptanceOutreach
      );
      const perimeterProtectionScore = getScore(
        assesmentResponse.perimeterProtection
      );
      const accessControlScore = getScore(assesmentResponse.accessControl);
      const SafetyResponseScore = getScore(assesmentResponse.safety_response);
      const electronicSecurityScore = getScore(
        assesmentResponse.electronicSecurity
      );
      const securityGuardingScore = getScore(
        assesmentResponse.securityGuarding
      );
      const locakdownScore = getScore(assesmentResponse.lockdown);
      const jamatkhanaSecurityScore = getScore(
        assesmentResponse.jamatkhanaSecurity
      );
      const StructuralResistanceAndProtectionScore = getScore(
        assesmentResponse.StructuralResistanceAndProtection
      );
      setLocationSecurityScore(locationSecurityScore);
      setAcceptanceOutreachScore(acceptanceOutreachScore);
      setPerimeterProtectionScore(perimeterProtectionScore);
      setAccessControlScore(accessControlScore);
      setSafetyResponseScore(SafetyResponseScore);
      setElectronicSecurityScore(electronicSecurityScore);
      setSecurityGuardingScore(securityGuardingScore);
      setLocakdownScore(locakdownScore);
      setJamatkhanaSecurityScore(jamatkhanaSecurityScore);
      setStructuralResistanceAndProtectionScore(
        StructuralResistanceAndProtectionScore
      );
    }
  }, [assesmentResponse]);

  const getSafetyScore = (score) => {
    if (score.total - score.unanswered === 0) {
      return "N/A";
    }
    return parseFloat(
      score.totalScore / (score.total - score.unanswered)
    ).toFixed(2);
  };

  const getColor = (total) => {
    const score = getSafetyScore(total);
    if (score === "N/A") {
      return "linear-gradient(to right bottom, #666666 45%, #ffffff 104%)";
    }
    if (score >= 90) {
      return "#c6efcd";
    }
    if (score >= 70) {
      return "#1e682b";
    }
    if (score >= 40) {
      return "#feff00";
    }
    if (score >= 10) {
      return "#ffc000";
    }
    return "#ff0000";
  };

  const getSecondaryText = (total) => {
    const score = getSafetyScore(total);
    if (score === "N/A") {
      return "";
    }
    if (score >= 90) {
      return "";
    }
    if (score >= 70) {
      return "Little or no improvement needed to meet acceptable risk.";
    }
    if (score >= 40) {
      return "In most cases, a plan is needed to improve security so to meet a level of acceptable risk.";
    }
    if (score >= 10) {
      return "Your premises are vulnerable, and a significant effort is required to meet acceptable risk.";
    }
    return "Your premises are very vulnerable, and a significant effort is required to meet acceptable risk.";
  };

  const getImage = (images) => {
    let text = "";
    //create link html for each image from array images
    for (let i = 0; i < images.length; i++) {
      let image = images[i];
      text += `<a target="_blank" style="color: blue; text-decoration: underline" href="${image}" >Image ${
        i + 1
      }</a>`;
    }
    console.log(text);
    return text;
  };

  const getTitleText = (total) => {
    const score = getSafetyScore(total);

    if (score === "N/A") {
      return "All questions answered as Not Applicable, see notes for details.";
    }
    if (score >= 90) {
      return "All proportional and appropriate measures in place and measures are completely effective.";
    }
    if (score >= 70) {
      return "A suitable number of proportionate and appropriate measures in place and measures are highly effective (weaknesses may exist if rigorously or severely tested).";
    }
    if (score >= 40) {
      return "Some measures in place OR the measures in place are somewhat effective.";
    }
    if (score >= 10) {
      return "Very few measures in place OR measures in place are not effective.";
    }
    return "No effective measures in place.";
  };

  const exportPdf = () => {
    setDownloadingPDF(true);
    const input = document.getElementById("capture");
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;
    const pdf = new jsPDF("l", "pt", "a4");
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;
    const pageHeightInPixels = inputHeight;
    const pages = pageHeightInPixels / height;

    const roundOff = Number(pages.toString().split(".")[1].substring(0, 1));
    const pageNo = roundOff > 0 ? pages + 1 : pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // origin
      for (let i = 1; i <= pageCount; i++) {
        const pdfStartingHeight = height * (i - 1);
        pdf.addImage(imgData, "PNG", 0, -pdfStartingHeight, width, inputHeight);
        if (i < pageCount) {
          pdf.addPage();
        }
      }
      setDownloadingPDF(false);
      pdf.save("assesment_2_download.pdf");
    });
  };

  const exportImage = () => {
    setDownloadingPDFImage(true);
    const input = document.getElementById("capture");
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;
    const pdf = new jsPDF("l", "pt", "a4");
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;
    const pageHeightInPixels = inputHeight;
    const pages = pageHeightInPixels / height;

    const roundOff = Number(pages.toString().split(".")[1].substring(0, 1));
    const pageNo = roundOff > 0 ? pages + 1 : pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      setDownloadingPDFImage(false);
      link.download = "assesment_2_download.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  if (!loaded) {
    return <div>Loading...</div>;
  }
  const {
    riskRegister,
    jamatkhanaProfile,
    assesmentDetails,
    jamatkhanaDetails,
    safety_response,
    acceptanceOutreach,
    accessControl,
    electronicSecurity,
    locationSecurity,
    lockdown,
    perimeterProtection,
    securityGuarding,
    jamatkhanaSecurity,
    StructuralResistanceAndProtection,
  } = assesmentResponse;

  return (
    <>
      <Row>
        <Col xs={6}>
          <h4>Assesment Id: # {assesmentId} </h4>
        </Col>
        <Col xs={6} style={{ textAlign: "right" }}>
          <Button onClick={exportPdf}>
            Download as PDF{" "}
            {downloadingPDF && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>{" "}
          &nbsp;
          <Button onClick={exportImage}>
            Download PDF Image
            {downloadingPDFImage && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>
        </Col>
      </Row>
      <Row className="mt-4" id="capture">
        <Col xs={12}>
          <Card>
            <Card.Header>
              <Card.Title>Assesment Results</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xl={12}>
                  <h4>Overall Score</h4>
                  {overallScore && (
                    <div>
                      <div>{`${
                        overallScore.total - overallScore.unanswered
                      } Question(s) answered / ${
                        overallScore.total
                      } Total Questions`}</div>
                      <div
                        style={{
                          background: getColor(overallScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage : {getSafetyScore(overallScore)}%
                        </div>
                        <div className="mt-2">{getTitleText(overallScore)}</div>
                        <div className="mt-2">
                          {getSecondaryText(overallScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Location Security</h4>
                  {locationSecurityScore && (
                    <div>
                      <div>{`${
                        locationSecurityScore.total -
                        locationSecurityScore.unanswered
                      } Question(s) answered / ${
                        locationSecurityScore.total
                      } Total Questions`}</div>
                      <div
                        style={{
                          background: getColor(locationSecurityScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage :{" "}
                          {getSafetyScore(locationSecurityScore)}%
                        </div>
                        <div className="mt-2">
                          {getTitleText(locationSecurityScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(locationSecurityScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Acceptance/Outreach</h4>
                  {acceptanceOutreachScore && (
                    <div>
                      <div>{`${
                        acceptanceOutreachScore.total -
                        acceptanceOutreachScore.unanswered
                      } Question(s) answered / ${
                        acceptanceOutreachScore.total
                      } Total Questions`}</div>
                      <div
                        style={{
                          background: getColor(acceptanceOutreachScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage :{" "}
                          {getSafetyScore(acceptanceOutreachScore)}%
                        </div>
                        <div className="mt-2">
                          {getTitleText(acceptanceOutreachScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(acceptanceOutreachScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Perimeter Protection</h4>
                  {perimeterProtectionScore && (
                    <div>
                      <div>{`${
                        perimeterProtectionScore.total -
                        perimeterProtectionScore.unanswered
                      } Question(s) answered / ${
                        perimeterProtectionScore.total
                      } Total Questions`}</div>
                      <div
                        style={{
                          background: getColor(perimeterProtectionScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage :{" "}
                          {getSafetyScore(perimeterProtectionScore)}%
                        </div>
                        <div className="mt-2">
                          {getTitleText(perimeterProtectionScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(perimeterProtectionScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Access Control</h4>
                  {accessControlScore && (
                    <div>
                      <div>{`${
                        accessControlScore.total - accessControlScore.unanswered
                      } Question(s) answered / ${
                        accessControlScore.total
                      } Total Questions`}</div>
                      <div
                        style={{
                          background: getColor(accessControlScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage :{" "}
                          {getSafetyScore(accessControlScore)}%
                        </div>
                        <div className="mt-2">
                          {getTitleText(accessControlScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(accessControlScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Structural Resistance and Protection</h4>
                  {StructuralResistanceAndProtectionScore && (
                    <div>
                      <div>{`${
                        StructuralResistanceAndProtectionScore.total -
                        StructuralResistanceAndProtectionScore.unanswered
                      } Question(s) answered / ${
                        StructuralResistanceAndProtectionScore.total
                      } Total Questions`}</div>
                      <div
                        style={{
                          background: getColor(
                            StructuralResistanceAndProtectionScore
                          ),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage :{" "}
                          {getSafetyScore(
                            StructuralResistanceAndProtectionScore
                          )}
                          %
                        </div>
                        <div className="mt-2">
                          {getTitleText(StructuralResistanceAndProtectionScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(
                            StructuralResistanceAndProtectionScore
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Jamatkhana Security</h4>
                  {jamatkhanaSecurityScore && (
                    <div>
                      <div>{`${
                        jamatkhanaSecurityScore.total -
                        jamatkhanaSecurityScore.unanswered
                      } Question(s) answered / ${
                        jamatkhanaSecurityScore.total
                      } Total Questions`}</div>
                      <div
                        style={{
                          background: getColor(jamatkhanaSecurityScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage :{" "}
                          {getSafetyScore(jamatkhanaSecurityScore)}%
                        </div>
                        <div className="mt-2">
                          {getTitleText(jamatkhanaSecurityScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(jamatkhanaSecurityScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Lockdown / Shelter in Place</h4>
                  {locakdownScore && (
                    <div>
                      <div>{`${
                        locakdownScore.total - locakdownScore.unanswered
                      } Question(s) answered / ${
                        locakdownScore.total
                      } Total Questions`}</div>
                      <div
                        style={{
                          background: getColor(locakdownScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage : {getSafetyScore(locakdownScore)}%
                        </div>
                        <div className="mt-2">
                          {getTitleText(locakdownScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(locakdownScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Private Security</h4>
                  {securityGuardingScore && (
                    <div>
                      <div>{`${
                        securityGuardingScore.total -
                        securityGuardingScore.unanswered
                      } Question(s) answered / ${
                        securityGuardingScore.total
                      } Total Questions`}</div>
                      <div
                        style={{
                          background: getColor(securityGuardingScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage :{" "}
                          {getSafetyScore(securityGuardingScore)}%
                        </div>
                        <div className="mt-2">
                          {getTitleText(securityGuardingScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(securityGuardingScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Electronic Security</h4>
                  {electronicSecurityScore && (
                    <div>
                      <div>{`${
                        electronicSecurityScore.total -
                        electronicSecurityScore.unanswered
                      } Question(s) answered / ${
                        electronicSecurityScore.total
                      } Total Questions`}</div>
                      <div
                        style={{
                          background: getColor(electronicSecurityScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage :{" "}
                          {getSafetyScore(electronicSecurityScore)}%
                        </div>
                        <div className="mt-2">
                          {getTitleText(electronicSecurityScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(electronicSecurityScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Safety / Response</h4>
                  {safetyResponseScore && (
                    <div>
                      <div>{`${
                        safetyResponseScore.total -
                        safetyResponseScore.unanswered
                      } Question(s) answered / ${
                        safetyResponseScore.total
                      } Total Questions`}</div>
                      <div
                        style={{
                          background: getColor(safetyResponseScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage :{" "}
                          {getSafetyScore(safetyResponseScore)}%
                        </div>
                        <div className="mt-2">
                          {getTitleText(safetyResponseScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(safetyResponseScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12}>
          <Card>
            <Card.Header>
              <Card.Title>Assesor and Site Info</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xl={12}>
                  <table className="w-full colored-table summary-table half-table">
                    <tbody>
                      <tr>
                        <td>Assessor Name</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.assesorFirstName.value &&
                            assesmentDetails.assesorFirstName.value}
                        </td>
                      </tr>
                      <tr>
                        <td>Assessor Email</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.assesorEmail.value &&
                            assesmentDetails.assesorEmail.value}
                        </td>
                      </tr>
                      <tr>
                        <td>Assessor PhoneNumber</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.assesorPhoneNumber.value &&
                            assesmentDetails.assesorPhoneNumber.value}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="w-full colored-table summary-table mt-4 half-table">
                    <tbody>
                      <tr>
                        <td>Co-Assessor Name</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.coAssessortFirstName.value &&
                            assesmentDetails.coAssessortFirstName.value}
                        </td>
                      </tr>
                      <tr>
                        <td>Co-Assessor Email</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.coAssesorEmail.value &&
                            assesmentDetails.coAssesorEmail.value}
                        </td>
                      </tr>
                      <tr>
                        <td>Co-Assessor PhoneNumber</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.coAssesorPhoneNumber.value &&
                            assesmentDetails.coAssesorPhoneNumber.value}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="w-full colored-table summary-table mt-4 half-table">
                    <tbody>
                      <tr>
                        <td>Site Name</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.siteName.value &&
                            assesmentDetails.siteName.value}
                        </td>
                      </tr>
                      <tr>
                        <td>Country</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.country.value &&
                            assesmentDetails.country.value.value}
                        </td>
                      </tr>
                      <tr>
                        <td>National Council</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.nationalCouncil.value &&
                            assesmentDetails.nationalCouncil.value.value}
                        </td>
                      </tr>
                      <tr>
                        <td>City</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.city.value &&
                            assesmentDetails.city.value}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12}>
          <Card>
            <Card.Header>
              <Card.Title>Instructions</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xl={12}>
                  <table className="min-w-full w-full colored-table">
                    <thead className="border-b">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm bg-green-500 font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          1.
                        </th>
                        <th
                          scope="col"
                          className="text-sm bg-blue-500 font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          2.
                        </th>
                        <th
                          scope="col"
                          className="text-sm bg-yellow-500 font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          3.
                        </th>
                        <th
                          scope="col"
                          className="text-sm bg-orange-500 font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          4.
                        </th>
                        <th
                          scope="col"
                          className=" bg-red-500 text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          5.
                        </th>
                        <th
                          scope="col"
                          className="na-int-color bg-red-500 text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          N/A.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-b">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Yes, completely acceptable and/or completely secure
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Yes, highly acceptable and/or highly secure
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Yes; generally acceptable and/or generally secure
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Yes: but unacceptable and/or insecure
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          No, completely unacceptable and/or completely insecure
                        </td>
                        <td
                          rowSpan="0"
                          className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                        >
                          Utilise the N/A option whenever faced with a question
                          which is Not Applicable in your context. When you
                          select this option, a drop-down menu will appear from
                          which to select the reason it is Not Applicable. This
                          includes an 'Other' option for you to add your own
                          comment.
                        </td>
                      </tr>
                      <tr className="bg-white border-b">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Yes, control measures are in place and are{" "}
                          <strong> completely </strong> proportionate, effective
                          and appropriate
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Yes, control measures are in place and are{" "}
                          <strong> highly </strong> proportionate. effective and
                          appropriate
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Yes, control measures are in place and are{" "}
                          <strong> generally </strong> proportionate, effective
                          and appropriate
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Yes, control measures are in place ar proportionate,{" "}
                          <strong> not </strong> effective and/or not
                          appropriate
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          No, control measures are{" "}
                          <strong> not in place and/or not </strong>{" "}
                          proportionate, not effective and not appropriate
                        </td>
                      </tr>
                      <tr className="bg-white border-b">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          No weaknesses exist
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Weaknesses may exist if severely tested
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Weaknesses may exist if routinely tested
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Weaknesses do exist if severely tested
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Weaknesses do exist if routinely tested
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12}>
          <Card>
            <Card.Header>
              <Card.Title>Assessment Answers</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xl={12}>
                  <h4>Premises Details</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Who is occupying the Jamatkhana?</td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.occupyingJamathkana &&
                          jamatkhanaDetails.occupyingJamathkana.value &&
                          jamatkhanaDetails.occupyingJamathkana.isNA &&
                          !jamatkhanaDetails.occupyingJamathkana.isNA.flag
                            ? jamatkhanaDetails.occupyingJamathkana.value.value
                            : jamatkhanaDetails.occupyingJamathkana &&
                              jamatkhanaDetails.occupyingJamathkana.isNA &&
                              jamatkhanaDetails.occupyingJamathkana.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.occupyingJamathkana &&
                          jamatkhanaDetails.occupyingJamathkana.isNA &&
                          jamatkhanaDetails.occupyingJamathkana.isNA
                            ? jamatkhanaDetails.occupyingJamathkana.isNA.comment
                            : jamatkhanaDetails.occupyingJamathkana &&
                              jamatkhanaDetails.occupyingJamathkana.comment
                            ? jamatkhanaDetails.occupyingJamathkana.comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.occupyingJamathkana &&
                          jamatkhanaDetails.occupyingJamathkana.image &&
                          jamatkhanaDetails.occupyingJamathkana.image.length >
                            0 ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaDetails.occupyingJamathkana.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Who owns the Jamatkhana?</td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.ownerJamatkhana &&
                          jamatkhanaDetails.ownerJamatkhana.value &&
                          jamatkhanaDetails.ownerJamatkhana.isNA &&
                          !jamatkhanaDetails.ownerJamatkhana.isNA.flag
                            ? jamatkhanaDetails.ownerJamatkhana.value.value
                            : jamatkhanaDetails.ownerJamatkhana &&
                              jamatkhanaDetails.ownerJamatkhana.isNA &&
                              jamatkhanaDetails.ownerJamatkhana.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.ownerJamatkhana &&
                          jamatkhanaDetails.ownerJamatkhana.isNA &&
                          jamatkhanaDetails.ownerJamatkhana.isNA
                            ? jamatkhanaDetails.ownerJamatkhana.isNA.comment
                            : jamatkhanaDetails.ownerJamatkhana &&
                              jamatkhanaDetails.ownerJamatkhana.comment
                            ? jamatkhanaDetails.ownerJamatkhana.comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.ownerJamatkhana &&
                          jamatkhanaDetails.ownerJamatkhana &&
                          jamatkhanaDetails.ownerJamatkhana.image &&
                          jamatkhanaDetails.ownerJamatkhana.image.length > 0 ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaDetails.ownerJamatkhana.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>What type of Jamatkhana is it?</td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.typeOfJamatKhana &&
                          jamatkhanaDetails.typeOfJamatKhana.value &&
                          jamatkhanaDetails.typeOfJamatKhana.isNA &&
                          !jamatkhanaDetails.typeOfJamatKhana.isNA.flag
                            ? jamatkhanaDetails.typeOfJamatKhana.value.value
                            : jamatkhanaDetails.typeOfJamatKhana &&
                              jamatkhanaDetails.typeOfJamatKhana.isNA &&
                              jamatkhanaDetails.typeOfJamatKhana.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.typeOfJamatKhana &&
                          jamatkhanaDetails.typeOfJamatKhana.isNA &&
                          jamatkhanaDetails.typeOfJamatKhana.isNA
                            ? jamatkhanaDetails.typeOfJamatKhana.isNA.comment
                            : jamatkhanaDetails.typeOfJamatKhana &&
                              jamatkhanaDetails.typeOfJamatKhana.comment
                            ? jamatkhanaDetails.typeOfJamatKhana.comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.typeOfJamatKhana &&
                          jamatkhanaDetails.typeOfJamatKhana &&
                          jamatkhanaDetails.typeOfJamatKhana.image &&
                          jamatkhanaDetails.typeOfJamatKhana.image.length >
                            0 ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaDetails.typeOfJamatKhana.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          What type of construction material is the Jamatkhana
                          made from?
                        </td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.constructionMaterial &&
                          jamatkhanaDetails.constructionMaterial.value &&
                          jamatkhanaDetails.constructionMaterial.isNA &&
                          !jamatkhanaDetails.constructionMaterial.isNA.flag
                            ? jamatkhanaDetails.constructionMaterial.value.value
                            : jamatkhanaDetails.constructionMaterial &&
                              jamatkhanaDetails.constructionMaterial.isNA &&
                              jamatkhanaDetails.constructionMaterial.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.constructionMaterial &&
                          jamatkhanaDetails.constructionMaterial.isNA &&
                          jamatkhanaDetails.constructionMaterial.isNA
                            ? jamatkhanaDetails.constructionMaterial.isNA
                                .comment
                            : jamatkhanaDetails.constructionMaterial &&
                              jamatkhanaDetails.constructionMaterial.comment
                            ? jamatkhanaDetails.constructionMaterial.comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.constructionMaterial &&
                          jamatkhanaDetails.constructionMaterial &&
                          jamatkhanaDetails.constructionMaterial.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaDetails.constructionMaterial.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Jamatkhana Description</td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.jamatkhanaDescription &&
                          jamatkhanaDetails.jamatkhanaDescription.value &&
                          jamatkhanaDetails.jamatkhanaDescription.isNA &&
                          !jamatkhanaDetails.jamatkhanaDescription.isNA.flag
                            ? jamatkhanaDetails.jamatkhanaDescription.value
                            : jamatkhanaDetails.jamatkhanaDescription &&
                              jamatkhanaDetails.jamatkhanaDescription.isNA &&
                              jamatkhanaDetails.jamatkhanaDescription.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.jamatkhanaDescription &&
                          jamatkhanaDetails.jamatkhanaDescription.isNA &&
                          jamatkhanaDetails.jamatkhanaDescription.isNA
                            ? jamatkhanaDetails.jamatkhanaDescription.isNA
                                .comment
                            : jamatkhanaDetails.jamatkhanaDescription &&
                              jamatkhanaDetails.jamatkhanaDescription.comment
                            ? jamatkhanaDetails.jamatkhanaDescription.comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.jamatkhanaDescription &&
                          jamatkhanaDetails.jamatkhanaDescription &&
                          jamatkhanaDetails.jamatkhanaDescription.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaDetails.jamatkhanaDescription.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col xl={12} className="mt-5">
                  <h4>Location Profile</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          List All the threats relevant to this Jamatkhana, as
                          identified in the Security Risk Assessment
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.listOfThreats &&
                          jamatkhanaProfile.listOfThreats.value ? (
                            <ul>
                              {jamatkhanaProfile.listOfThreats.value.map(
                                (item, index) => {
                                  return (
                                    <li key={`list_of_threat_${index}`}>
                                      {item.id}. {item.value}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          ) : jamatkhanaProfile.listOfThreats &&
                            jamatkhanaProfile.listOfThreats.isNA &&
                            jamatkhanaProfile.listOfThreats.isNA.flag ? (
                            "Not Applicable"
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.listOfThreats &&
                          jamatkhanaProfile.listOfThreats.isNA &&
                          jamatkhanaProfile.listOfThreats.isNA
                            ? jamatkhanaProfile.listOfThreats.isNA.comment
                            : jamatkhanaProfile.listOfThreats &&
                              jamatkhanaProfile.listOfThreats.comment
                            ? jamatkhanaProfile.listOfThreats
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.listOfThreats &&
                          jamatkhanaProfile.listOfThreats &&
                          jamatkhanaProfile.listOfThreats.image &&
                          jamatkhanaProfile.listOfThreats.image.length > 0 ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile.listOfThreats
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          is there a current and accurate security risk
                          assesment in place for the region that this jamatkhana
                          is located in?
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.currentSecurityRisk &&
                          jamatkhanaProfile.currentSecurityRisk.value &&
                          jamatkhanaProfile.currentSecurityRisk.isNA &&
                          !jamatkhanaProfile.currentSecurityRisk.isNA.flag
                            ? jamatkhanaProfile.currentSecurityRisk.value
                            : jamatkhanaProfile.currentSecurityRisk &&
                              jamatkhanaProfile.currentSecurityRisk.isNA &&
                              jamatkhanaProfile.currentSecurityRisk.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.currentSecurityRisk &&
                          jamatkhanaProfile.currentSecurityRisk.isNA &&
                          jamatkhanaProfile.currentSecurityRisk.isNA
                            ? jamatkhanaProfile.currentSecurityRisk.isNA.comment
                            : jamatkhanaProfile.currentSecurityRisk &&
                              jamatkhanaProfile.currentSecurityRisk.comment
                            ? jamatkhanaProfile.currentSecurityRisk.comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.currentSecurityRisk &&
                          jamatkhanaProfile.currentSecurityRisk &&
                          jamatkhanaProfile.currentSecurityRisk.image &&
                          jamatkhanaProfile.currentSecurityRisk.image.length >
                            0 ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile.currentSecurityRisk.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the use/purpose of Jamatkhana compliment the
                          neighbourhood?
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.jamatkhanaPurpose &&
                          jamatkhanaProfile.jamatkhanaPurpose.value &&
                          jamatkhanaProfile.jamatkhanaPurpose.isNA &&
                          !jamatkhanaProfile.jamatkhanaPurpose.isNA.flag
                            ? jamatkhanaProfile.jamatkhanaPurpose.value
                            : jamatkhanaProfile.jamatkhanaPurpose &&
                              jamatkhanaProfile.jamatkhanaPurpose.isNA &&
                              jamatkhanaProfile.jamatkhanaPurpose.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.jamatkhanaPurpose &&
                          jamatkhanaProfile.jamatkhanaPurpose.isNA &&
                          jamatkhanaProfile.jamatkhanaPurpose.isNA
                            ? jamatkhanaProfile.jamatkhanaPurpose.isNA.comment
                            : jamatkhanaProfile.jamatkhanaPurpose &&
                              jamatkhanaProfile.jamatkhanaPurpose.comment
                            ? jamatkhanaProfile.jamatkhanaPurpose.comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.jamatkhanaPurpose &&
                          jamatkhanaProfile.jamatkhanaPurpose &&
                          jamatkhanaProfile.jamatkhanaPurpose.image &&
                          jamatkhanaProfile.jamatkhanaPurpose.image.length >
                            0 ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile.jamatkhanaPurpose.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does Jamatkhana have iconic Status(i.e is it well
                          known symbolic facility)?
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.JamatkhanaIconicStatus &&
                          jamatkhanaProfile.JamatkhanaIconicStatus.value &&
                          jamatkhanaProfile.JamatkhanaIconicStatus.isNA &&
                          !jamatkhanaProfile.JamatkhanaIconicStatus.isNA.flag
                            ? jamatkhanaProfile.JamatkhanaIconicStatus.value
                            : jamatkhanaProfile.JamatkhanaIconicStatus &&
                              jamatkhanaProfile.JamatkhanaIconicStatus.isNA &&
                              jamatkhanaProfile.JamatkhanaIconicStatus.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.JamatkhanaIconicStatus &&
                          jamatkhanaProfile.JamatkhanaIconicStatus.isNA &&
                          jamatkhanaProfile.JamatkhanaIconicStatus.isNA
                            ? jamatkhanaProfile.JamatkhanaIconicStatus.isNA
                                .comment
                            : jamatkhanaProfile.JamatkhanaIconicStatus &&
                              jamatkhanaProfile.JamatkhanaIconicStatus.comment
                            ? jamatkhanaProfile.JamatkhanaIconicStatus.comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.JamatkhanaIconicStatus &&
                          jamatkhanaProfile.JamatkhanaIconicStatus &&
                          jamatkhanaProfile.JamatkhanaIconicStatus.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile.JamatkhanaIconicStatus.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          is it neighbourhood considered to be safe and secure?
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.safeNeighbourhood &&
                          jamatkhanaProfile.safeNeighbourhood.value &&
                          jamatkhanaProfile.safeNeighbourhood.isNA &&
                          !jamatkhanaProfile.safeNeighbourhood.isNA.flag
                            ? jamatkhanaProfile.safeNeighbourhood.value
                            : jamatkhanaProfile.safeNeighbourhood &&
                              jamatkhanaProfile.safeNeighbourhood.isNA &&
                              jamatkhanaProfile.safeNeighbourhood.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.safeNeighbourhood &&
                          jamatkhanaProfile.safeNeighbourhood.isNA &&
                          jamatkhanaProfile.safeNeighbourhood.isNA
                            ? jamatkhanaProfile.safeNeighbourhood.isNA.comment
                            : jamatkhanaProfile.safeNeighbourhood &&
                              jamatkhanaProfile.safeNeighbourhood.comment
                            ? jamatkhanaProfile.safeNeighbourhood.comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.safeNeighbourhood &&
                          jamatkhanaProfile.safeNeighbourhood &&
                          jamatkhanaProfile.safeNeighbourhood.image &&
                          jamatkhanaProfile.safeNeighbourhood.image.length >
                            0 ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile.safeNeighbourhood.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are Police/goverment security forces in control of the
                          area?
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.areaInControlOfPoliceAndGoverment &&
                          jamatkhanaProfile.areaInControlOfPoliceAndGoverment
                            .value &&
                          jamatkhanaProfile.areaInControlOfPoliceAndGoverment
                            .isNA &&
                          !jamatkhanaProfile.areaInControlOfPoliceAndGoverment
                            .isNA.flag
                            ? jamatkhanaProfile
                                .areaInControlOfPoliceAndGoverment.value
                            : jamatkhanaProfile.areaInControlOfPoliceAndGoverment &&
                              jamatkhanaProfile
                                .areaInControlOfPoliceAndGoverment.isNA &&
                              jamatkhanaProfile
                                .areaInControlOfPoliceAndGoverment.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.areaInControlOfPoliceAndGoverment &&
                          jamatkhanaProfile.areaInControlOfPoliceAndGoverment
                            .isNA &&
                          jamatkhanaProfile.areaInControlOfPoliceAndGoverment
                            .isNA
                            ? jamatkhanaProfile
                                .areaInControlOfPoliceAndGoverment.isNA.comment
                            : jamatkhanaProfile.areaInControlOfPoliceAndGoverment &&
                              jamatkhanaProfile
                                .areaInControlOfPoliceAndGoverment.comment
                            ? jamatkhanaProfile
                                .areaInControlOfPoliceAndGoverment.comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.areaInControlOfPoliceAndGoverment &&
                          jamatkhanaProfile.areaInControlOfPoliceAndGoverment &&
                          jamatkhanaProfile.areaInControlOfPoliceAndGoverment
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile
                                    .areaInControlOfPoliceAndGoverment.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          is the profile of jamatkhana appropriate for it's
                          function?
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.jamatkhanaProfile &&
                          jamatkhanaProfile.jamatkhanaProfile.value &&
                          jamatkhanaProfile.jamatkhanaProfile.isNA &&
                          !jamatkhanaProfile.jamatkhanaProfile.isNA.flag
                            ? jamatkhanaProfile.jamatkhanaProfile.value
                            : jamatkhanaProfile.jamatkhanaProfile &&
                              jamatkhanaProfile.jamatkhanaProfile.isNA &&
                              jamatkhanaProfile.jamatkhanaProfile.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.jamatkhanaProfile &&
                          jamatkhanaProfile.jamatkhanaProfile.isNA &&
                          jamatkhanaProfile.jamatkhanaProfile.isNA
                            ? jamatkhanaProfile.jamatkhanaProfile.isNA.comment
                            : jamatkhanaProfile.jamatkhanaProfile &&
                              jamatkhanaProfile.jamatkhanaProfile.comment
                            ? jamatkhanaProfile.jamatkhanaProfile.comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.jamatkhanaProfile &&
                          jamatkhanaProfile.jamatkhanaProfile &&
                          jamatkhanaProfile.jamatkhanaProfile.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile.jamatkhanaProfile.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>What is the average weekday morning attendence?</td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageWeekdayMorningAttendance &&
                          jamatkhanaProfile.averageWeekdayMorningAttendance
                            .value &&
                          jamatkhanaProfile.averageWeekdayMorningAttendance
                            .isNA &&
                          !jamatkhanaProfile.averageWeekdayMorningAttendance
                            .isNA.flag
                            ? jamatkhanaProfile.averageWeekdayMorningAttendance
                                .value
                            : jamatkhanaProfile.averageWeekdayMorningAttendance &&
                              jamatkhanaProfile.averageWeekdayMorningAttendance
                                .isNA &&
                              jamatkhanaProfile.averageWeekdayMorningAttendance
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageWeekdayMorningAttendance &&
                          jamatkhanaProfile.averageWeekdayMorningAttendance
                            .isNA &&
                          jamatkhanaProfile.averageWeekdayMorningAttendance.isNA
                            ? jamatkhanaProfile.averageWeekdayMorningAttendance
                                .isNA.comment
                            : jamatkhanaProfile.averageWeekdayMorningAttendance &&
                              jamatkhanaProfile.averageWeekdayMorningAttendance
                                .comment
                            ? jamatkhanaProfile.averageWeekdayMorningAttendance
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageWeekdayMorningAttendance &&
                          jamatkhanaProfile.averageWeekdayMorningAttendance &&
                          jamatkhanaProfile.averageWeekdayMorningAttendance
                            .image &&
                          jamatkhanaProfile.averageWeekdayMorningAttendance
                            .image.length > 0 ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile
                                    .averageWeekdayMorningAttendance.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>What is the average weekday evening attendence?</td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageWeekdayEveningAttendance &&
                          jamatkhanaProfile.averageWeekdayEveningAttendance
                            .value &&
                          jamatkhanaProfile.averageWeekdayEveningAttendance
                            .isNA &&
                          !jamatkhanaProfile.averageWeekdayEveningAttendance
                            .isNA.flag
                            ? jamatkhanaProfile.averageWeekdayEveningAttendance
                                .value
                            : jamatkhanaProfile.averageWeekdayEveningAttendance &&
                              jamatkhanaProfile.averageWeekdayEveningAttendance
                                .isNA &&
                              jamatkhanaProfile.averageWeekdayEveningAttendance
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageWeekdayEveningAttendance &&
                          jamatkhanaProfile.averageWeekdayEveningAttendance
                            .isNA &&
                          jamatkhanaProfile.averageWeekdayEveningAttendance.isNA
                            ? jamatkhanaProfile.averageWeekdayEveningAttendance
                                .isNA.comment
                            : jamatkhanaProfile.averageWeekdayEveningAttendance &&
                              jamatkhanaProfile.averageWeekdayEveningAttendance
                                .comment
                            ? jamatkhanaProfile.averageWeekdayEveningAttendance
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageWeekdayEveningAttendance &&
                          jamatkhanaProfile.averageWeekdayEveningAttendance &&
                          jamatkhanaProfile.averageWeekdayEveningAttendance
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile
                                    .averageWeekdayEveningAttendance.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>What is the average Friday morning attendence?</td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageFridayMorningAttendance &&
                          jamatkhanaProfile.averageFridayMorningAttendance
                            .value &&
                          jamatkhanaProfile.averageFridayMorningAttendance &&
                          jamatkhanaProfile.averageFridayMorningAttendance
                            .isNA &&
                          !jamatkhanaProfile.averageFridayMorningAttendance.isNA
                            .flag.value
                            ? jamatkhanaProfile.averageFridayMorningAttendance
                                .value
                            : jamatkhanaProfile.averageFridayMorningAttendance &&
                              jamatkhanaProfile.averageFridayMorningAttendance
                                .isNA &&
                              jamatkhanaProfile.averageFridayMorningAttendance
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageFridayMorningAttendance &&
                          jamatkhanaProfile.averageFridayMorningAttendance
                            .isNA &&
                          jamatkhanaProfile.averageFridayMorningAttendance.isNA
                            ? jamatkhanaProfile.averageFridayMorningAttendance
                                .isNA.comment
                            : jamatkhanaProfile.averageFridayMorningAttendance &&
                              jamatkhanaProfile.averageFridayMorningAttendance
                                .comment
                            ? jamatkhanaProfile.averageFridayMorningAttendance
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageFridayMorningAttendance &&
                          jamatkhanaProfile.averageFridayMorningAttendance &&
                          jamatkhanaProfile.averageFridayMorningAttendance
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile
                                    .averageFridayMorningAttendance.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>What is the average Friday evening attendence?</td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageFridayEveningAttendance &&
                          jamatkhanaProfile.averageFridayEveningAttendance
                            .value &&
                          jamatkhanaProfile.averageFridayEveningAttendance
                            .isNA &&
                          !jamatkhanaProfile.averageFridayEveningAttendance.isNA
                            .flag
                            ? jamatkhanaProfile.averageFridayEveningAttendance
                                .value
                            : jamatkhanaProfile.averageFridayEveningAttendance &&
                              jamatkhanaProfile.averageFridayEveningAttendance
                                .isNA &&
                              jamatkhanaProfile.averageFridayEveningAttendance
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageFridayEveningAttendance &&
                          jamatkhanaProfile.averageFridayEveningAttendance
                            .isNA &&
                          jamatkhanaProfile.averageFridayEveningAttendance.isNA
                            ? jamatkhanaProfile.averageFridayEveningAttendance
                                .isNA.comment
                            : jamatkhanaProfile.averageFridayEveningAttendance &&
                              jamatkhanaProfile.averageFridayEveningAttendance
                                .comment
                            ? jamatkhanaProfile.averageFridayEveningAttendance
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageFridayEveningAttendance &&
                          jamatkhanaProfile.averageFridayEveningAttendance &&
                          jamatkhanaProfile.averageFridayEveningAttendance
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile
                                    .averageFridayEveningAttendance.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>What is the maximum attendence?</td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.maximumAttendence &&
                          jamatkhanaProfile.maximumAttendence.value &&
                          jamatkhanaProfile.maximumAttendence.isNA &&
                          !jamatkhanaProfile.maximumAttendence.isNA.flag
                            ? jamatkhanaProfile.maximumAttendence.value
                            : jamatkhanaProfile.maximumAttendence &&
                              jamatkhanaProfile.maximumAttendence.isNA &&
                              jamatkhanaProfile.maximumAttendence.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.maximumAttendence &&
                          jamatkhanaProfile.maximumAttendence.isNA &&
                          jamatkhanaProfile.maximumAttendence.isNA
                            ? jamatkhanaProfile.maximumAttendence.isNA.comment
                            : jamatkhanaProfile.maximumAttendence &&
                              jamatkhanaProfile.maximumAttendence.comment
                            ? jamatkhanaProfile.maximumAttendence.comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.maximumAttendence &&
                          jamatkhanaProfile.maximumAttendence &&
                          jamatkhanaProfile.maximumAttendence.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile.maximumAttendence.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col xl={12} className="mt-5">
                  <h4>Location Security</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Are you able to move safely in the area by foot during
                          morning congregation times?
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.abilityToMoveByFootSafetlyInAreaINMorning &&
                          locationSecurity
                            .abilityToMoveByFootSafetlyInAreaINMorning.value &&
                          locationSecurity
                            .abilityToMoveByFootSafetlyInAreaINMorning.isNA &&
                          !locationSecurity
                            .abilityToMoveByFootSafetlyInAreaINMorning.isNA.flag
                            ? getScoreFromAnswer(
                                locationSecurity
                                  .abilityToMoveByFootSafetlyInAreaINMorning
                                  .value.value
                              )
                            : locationSecurity.abilityToMoveByFootSafetlyInAreaINMorning &&
                              locationSecurity
                                .abilityToMoveByFootSafetlyInAreaINMorning
                                .isNA &&
                              locationSecurity
                                .abilityToMoveByFootSafetlyInAreaINMorning.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.abilityToMoveByFootSafetlyInAreaINMorning &&
                          locationSecurity
                            .abilityToMoveByFootSafetlyInAreaINMorning.isNA &&
                          locationSecurity
                            .abilityToMoveByFootSafetlyInAreaINMorning.isNA
                            ? locationSecurity
                                .abilityToMoveByFootSafetlyInAreaINMorning.isNA
                                .comment
                            : locationSecurity.abilityToMoveByFootSafetlyInAreaINMorning &&
                              locationSecurity
                                .abilityToMoveByFootSafetlyInAreaINMorning
                                .comment
                            ? locationSecurity
                                .abilityToMoveByFootSafetlyInAreaINMorning
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.abilityToMoveByFootSafetlyInAreaINMorning &&
                          locationSecurity.abilityToMoveByFootSafetlyInAreaINMorning &&
                          locationSecurity
                            .abilityToMoveByFootSafetlyInAreaINMorning.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  locationSecurity
                                    .abilityToMoveByFootSafetlyInAreaINMorning
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are you able to move safely in the area by foot during
                          evening congregation times?
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.abilityToMoveByFootSafetlyInAreaINEvening &&
                          locationSecurity
                            .abilityToMoveByFootSafetlyInAreaINEvening.value &&
                          locationSecurity
                            .abilityToMoveByFootSafetlyInAreaINEvening.isNA &&
                          !locationSecurity
                            .abilityToMoveByFootSafetlyInAreaINEvening.isNA.flag
                            ? getScoreFromAnswer(
                                locationSecurity
                                  .abilityToMoveByFootSafetlyInAreaINEvening
                                  .value.value
                              )
                            : locationSecurity.abilityToMoveByFootSafetlyInAreaINEvening &&
                              locationSecurity
                                .abilityToMoveByFootSafetlyInAreaINEvening
                                .isNA &&
                              locationSecurity
                                .abilityToMoveByFootSafetlyInAreaINEvening.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.abilityToMoveByFootSafetlyInAreaINEvening &&
                          locationSecurity
                            .abilityToMoveByFootSafetlyInAreaINEvening.isNA &&
                          locationSecurity
                            .abilityToMoveByFootSafetlyInAreaINEvening.isNA
                            ? locationSecurity
                                .abilityToMoveByFootSafetlyInAreaINEvening.isNA
                                .comment
                            : locationSecurity.abilityToMoveByFootSafetlyInAreaINEvening &&
                              locationSecurity
                                .abilityToMoveByFootSafetlyInAreaINEvening
                                .comment
                            ? locationSecurity
                                .abilityToMoveByFootSafetlyInAreaINEvening
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.abilityToMoveByFootSafetlyInAreaINEvening &&
                          locationSecurity.abilityToMoveByFootSafetlyInAreaINEvening &&
                          locationSecurity
                            .abilityToMoveByFootSafetlyInAreaINEvening.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  locationSecurity
                                    .abilityToMoveByFootSafetlyInAreaINEvening
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are you able to move safely in the area by car during
                          morning congregation times?
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.abilityToMoveByCarSafetlyInAreaInMorning &&
                          locationSecurity
                            .abilityToMoveByCarSafetlyInAreaInMorning.value &&
                          locationSecurity
                            .abilityToMoveByCarSafetlyInAreaInMorning.isNA &&
                          !locationSecurity
                            .abilityToMoveByCarSafetlyInAreaInMorning.isNA.flag
                            ? getScoreFromAnswer(
                                locationSecurity
                                  .abilityToMoveByCarSafetlyInAreaInMorning
                                  .value.value
                              )
                            : locationSecurity.abilityToMoveByCarSafetlyInAreaInMorning &&
                              locationSecurity
                                .abilityToMoveByCarSafetlyInAreaInMorning
                                .isNA &&
                              locationSecurity
                                .abilityToMoveByCarSafetlyInAreaInMorning.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.abilityToMoveByCarSafetlyInAreaInMorning &&
                          locationSecurity
                            .abilityToMoveByCarSafetlyInAreaInMorning.isNA &&
                          locationSecurity
                            .abilityToMoveByCarSafetlyInAreaInMorning.isNA
                            ? locationSecurity
                                .abilityToMoveByCarSafetlyInAreaInMorning.isNA
                                .comment
                            : locationSecurity.abilityToMoveByCarSafetlyInAreaInMorning &&
                              locationSecurity
                                .abilityToMoveByCarSafetlyInAreaInMorning
                                .comment
                            ? locationSecurity
                                .abilityToMoveByCarSafetlyInAreaInMorning
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.abilityToMoveByCarSafetlyInAreaInMorning &&
                          locationSecurity.abilityToMoveByCarSafetlyInAreaInMorning &&
                          locationSecurity
                            .abilityToMoveByCarSafetlyInAreaInMorning.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  locationSecurity
                                    .abilityToMoveByCarSafetlyInAreaInMorning
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are you able to move safely in the area by car during
                          evening congregation times?
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.abilityToMoveByCarSafetlyInAreaInEvening &&
                          locationSecurity
                            .abilityToMoveByCarSafetlyInAreaInEvening.value &&
                          locationSecurity
                            .abilityToMoveByCarSafetlyInAreaInEvening.isNA &&
                          !locationSecurity
                            .abilityToMoveByCarSafetlyInAreaInEvening.isNA.flag
                            ? getScoreFromAnswer(
                                locationSecurity
                                  .abilityToMoveByCarSafetlyInAreaInEvening
                                  .value.value
                              )
                            : locationSecurity.abilityToMoveByCarSafetlyInAreaInEvening &&
                              locationSecurity
                                .abilityToMoveByCarSafetlyInAreaInEvening
                                .isNA &&
                              locationSecurity
                                .abilityToMoveByCarSafetlyInAreaInEvening.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.abilityToMoveByCarSafetlyInAreaInEvening &&
                          locationSecurity
                            .abilityToMoveByCarSafetlyInAreaInEvening.isNA &&
                          locationSecurity
                            .abilityToMoveByCarSafetlyInAreaInEvening.isNA
                            ? locationSecurity
                                .abilityToMoveByCarSafetlyInAreaInEvening.isNA
                                .comment
                            : locationSecurity.abilityToMoveByCarSafetlyInAreaInEvening &&
                              locationSecurity
                                .abilityToMoveByCarSafetlyInAreaInEvening
                                .comment
                            ? locationSecurity
                                .abilityToMoveByCarSafetlyInAreaInEvening
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.abilityToMoveByCarSafetlyInAreaInEvening &&
                          locationSecurity.abilityToMoveByCarSafetlyInAreaInEvening &&
                          locationSecurity
                            .abilityToMoveByCarSafetlyInAreaInEvening.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  locationSecurity
                                    .abilityToMoveByCarSafetlyInAreaInEvening
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the premises and organisation minimise their
                          profile to criminals?
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.minimiseProfileToCriminals &&
                          locationSecurity.minimiseProfileToCriminals.value &&
                          locationSecurity.minimiseProfileToCriminals.isNA &&
                          !locationSecurity.minimiseProfileToCriminals.isNA.flag
                            ? getScoreFromAnswer(
                                locationSecurity.minimiseProfileToCriminals
                                  .value.value
                              )
                            : locationSecurity.minimiseProfileToCriminals &&
                              locationSecurity.minimiseProfileToCriminals
                                .isNA &&
                              locationSecurity.minimiseProfileToCriminals.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.minimiseProfileToCriminals &&
                          locationSecurity.minimiseProfileToCriminals.isNA &&
                          locationSecurity.minimiseProfileToCriminals.isNA
                            ? locationSecurity.minimiseProfileToCriminals.isNA
                                .comment
                            : locationSecurity.minimiseProfileToCriminals &&
                              locationSecurity.minimiseProfileToCriminals
                                .comment
                            ? locationSecurity.minimiseProfileToCriminals
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.minimiseProfileToCriminals &&
                          locationSecurity.minimiseProfileToCriminals &&
                          locationSecurity.minimiseProfileToCriminals.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  locationSecurity.minimiseProfileToCriminals
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the Jamatkhana and / or community (Jamat) present
                          a low profile, so as to mitigate the risk of being
                          targeted?
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.presentALowProfile &&
                          locationSecurity.presentALowProfile.value &&
                          locationSecurity.presentALowProfile.isNA &&
                          !locationSecurity.presentALowProfile.isNA.flag
                            ? getScoreFromAnswer(
                                locationSecurity.presentALowProfile.value.value
                              )
                            : locationSecurity.presentALowProfile &&
                              locationSecurity.presentALowProfile.isNA &&
                              locationSecurity.presentALowProfile.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.presentALowProfile &&
                          locationSecurity.presentALowProfile.isNA &&
                          locationSecurity.presentALowProfile.isNA
                            ? locationSecurity.presentALowProfile.isNA.comment
                            : locationSecurity.presentALowProfile &&
                              locationSecurity.presentALowProfile.comment
                            ? locationSecurity.presentALowProfile.comment
                            : "-"}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.presentALowProfile &&
                          locationSecurity.presentALowProfile &&
                          locationSecurity.presentALowProfile.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  locationSecurity.presentALowProfile.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the community maintain good relationships with
                          its neighbours?
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.maintainGoodRelationWithNeighbours &&
                          locationSecurity.maintainGoodRelationWithNeighbours
                            .value &&
                          locationSecurity.maintainGoodRelationWithNeighbours
                            .isNA &&
                          !locationSecurity.maintainGoodRelationWithNeighbours
                            .isNA.flag
                            ? getScoreFromAnswer(
                                locationSecurity
                                  .maintainGoodRelationWithNeighbours.value
                                  .value
                              )
                            : locationSecurity.maintainGoodRelationWithNeighbours &&
                              locationSecurity
                                .maintainGoodRelationWithNeighbours.isNA &&
                              locationSecurity
                                .maintainGoodRelationWithNeighbours.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.maintainGoodRelationWithNeighbours &&
                          locationSecurity.maintainGoodRelationWithNeighbours
                            .isNA &&
                          locationSecurity.maintainGoodRelationWithNeighbours
                            .isNA
                            ? locationSecurity
                                .maintainGoodRelationWithNeighbours.isNA.comment
                            : locationSecurity.maintainGoodRelationWithNeighbours &&
                              locationSecurity
                                .maintainGoodRelationWithNeighbours.comment
                            ? locationSecurity
                                .maintainGoodRelationWithNeighbours.comment
                            : "-"}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.maintainGoodRelationWithNeighbours &&
                          locationSecurity.maintainGoodRelationWithNeighbours &&
                          locationSecurity.maintainGoodRelationWithNeighbours
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  locationSecurity
                                    .maintainGoodRelationWithNeighbours.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Acceptance/Outreach</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Does the community have a proactive and ongoing
                          acceptance/outreach program?
                        </td>
                        <td>
                          {acceptanceOutreach &&
                          acceptanceOutreach.ongoingAcceptanceProgram &&
                          acceptanceOutreach.ongoingAcceptanceProgram.value &&
                          acceptanceOutreach.ongoingAcceptanceProgram.isNA &&
                          !acceptanceOutreach.ongoingAcceptanceProgram.isNA.flag
                            ? getScoreFromAnswer(
                                acceptanceOutreach.ongoingAcceptanceProgram
                                  .value.value
                              )
                            : acceptanceOutreach.ongoingAcceptanceProgram &&
                              acceptanceOutreach.ongoingAcceptanceProgram
                                .isNA &&
                              acceptanceOutreach.ongoingAcceptanceProgram.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {acceptanceOutreach &&
                          acceptanceOutreach.ongoingAcceptanceProgram &&
                          acceptanceOutreach.ongoingAcceptanceProgram.isNA &&
                          acceptanceOutreach.ongoingAcceptanceProgram.isNA
                            ? acceptanceOutreach.ongoingAcceptanceProgram.isNA
                                .comment
                            : acceptanceOutreach.ongoingAcceptanceProgram &&
                              acceptanceOutreach.ongoingAcceptanceProgram
                                .comment
                            ? acceptanceOutreach.ongoingAcceptanceProgram
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {acceptanceOutreach &&
                          acceptanceOutreach.ongoingAcceptanceProgram &&
                          acceptanceOutreach.ongoingAcceptanceProgram &&
                          acceptanceOutreach.ongoingAcceptanceProgram.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  acceptanceOutreach.ongoingAcceptanceProgram
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the acceptance/outreach program successfully
                          enhance the safety of members of the Jamat in and
                          around the Jamatkhana?
                        </td>
                        <td>
                          {acceptanceOutreach &&
                          acceptanceOutreach.programEnhancingSafetyInAndAround &&
                          acceptanceOutreach.programEnhancingSafetyInAndAround
                            .value &&
                          acceptanceOutreach.programEnhancingSafetyInAndAround
                            .isNA &&
                          !acceptanceOutreach.programEnhancingSafetyInAndAround
                            .isNA.flag
                            ? getScoreFromAnswer(
                                acceptanceOutreach
                                  .programEnhancingSafetyInAndAround.value.value
                              )
                            : acceptanceOutreach.programEnhancingSafetyInAndAround &&
                              acceptanceOutreach
                                .programEnhancingSafetyInAndAround.isNA &&
                              acceptanceOutreach
                                .programEnhancingSafetyInAndAround.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {acceptanceOutreach &&
                          acceptanceOutreach.programEnhancingSafetyInAndAround &&
                          acceptanceOutreach.programEnhancingSafetyInAndAround
                            .isNA &&
                          acceptanceOutreach.programEnhancingSafetyInAndAround
                            .isNA
                            ? acceptanceOutreach
                                .programEnhancingSafetyInAndAround.isNA.comment
                            : acceptanceOutreach.programEnhancingSafetyInAndAround &&
                              acceptanceOutreach
                                .programEnhancingSafetyInAndAround.comment
                            ? acceptanceOutreach
                                .programEnhancingSafetyInAndAround.comment
                            : "-"}
                        </td>
                        <td>
                          {acceptanceOutreach &&
                          acceptanceOutreach.programEnhancingSafetyInAndAround &&
                          acceptanceOutreach.programEnhancingSafetyInAndAround &&
                          acceptanceOutreach.programEnhancingSafetyInAndAround
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  acceptanceOutreach
                                    .programEnhancingSafetyInAndAround.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the acceptance/outreach program successfully
                          enhance safety of members of the Jamat traveling
                          to/from Jamatkhana?
                        </td>
                        <td>
                          {acceptanceOutreach &&
                          acceptanceOutreach.programEnhancingSafetyToAndFro &&
                          acceptanceOutreach.programEnhancingSafetyToAndFro &&
                          acceptanceOutreach.programEnhancingSafetyToAndFro
                            .isNA &&
                          !acceptanceOutreach.programEnhancingSafetyToAndFro
                            .isNA.flag.value
                            ? getScoreFromAnswer(
                                acceptanceOutreach
                                  .programEnhancingSafetyToAndFro.value.value
                              )
                            : acceptanceOutreach.programEnhancingSafetyToAndFro &&
                              acceptanceOutreach.programEnhancingSafetyToAndFro
                                .isNA &&
                              acceptanceOutreach.programEnhancingSafetyToAndFro
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {acceptanceOutreach &&
                          acceptanceOutreach.programEnhancingSafetyToAndFro &&
                          acceptanceOutreach.programEnhancingSafetyToAndFro
                            .isNA &&
                          acceptanceOutreach.programEnhancingSafetyToAndFro.isNA
                            ? acceptanceOutreach.programEnhancingSafetyToAndFro
                                .isNA.comment
                            : acceptanceOutreach.programEnhancingSafetyToAndFro &&
                              acceptanceOutreach.programEnhancingSafetyToAndFro
                                .comment
                            ? acceptanceOutreach.programEnhancingSafetyToAndFro
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {acceptanceOutreach &&
                          acceptanceOutreach.programEnhancingSafetyToAndFro &&
                          acceptanceOutreach.programEnhancingSafetyToAndFro &&
                          acceptanceOutreach.programEnhancingSafetyToAndFro
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  acceptanceOutreach
                                    .programEnhancingSafetyToAndFro.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the community manage the development of long-term
                          relationships with its neighbours?
                        </td>
                        <td>
                          {acceptanceOutreach &&
                          acceptanceOutreach.managingLongTermRelationshipsWithNeighbours &&
                          acceptanceOutreach
                            .managingLongTermRelationshipsWithNeighbours
                            .value &&
                          acceptanceOutreach
                            .managingLongTermRelationshipsWithNeighbours.isNA &&
                          !acceptanceOutreach
                            .managingLongTermRelationshipsWithNeighbours.isNA
                            .flag
                            ? getScoreFromAnswer(
                                acceptanceOutreach
                                  .managingLongTermRelationshipsWithNeighbours
                                  .value.value
                              )
                            : acceptanceOutreach.managingLongTermRelationshipsWithNeighbours &&
                              acceptanceOutreach
                                .managingLongTermRelationshipsWithNeighbours
                                .isNA &&
                              acceptanceOutreach
                                .managingLongTermRelationshipsWithNeighbours
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {acceptanceOutreach &&
                          acceptanceOutreach.managingLongTermRelationshipsWithNeighbours &&
                          acceptanceOutreach
                            .managingLongTermRelationshipsWithNeighbours.isNA &&
                          acceptanceOutreach
                            .managingLongTermRelationshipsWithNeighbours.isNA
                            ? acceptanceOutreach
                                .managingLongTermRelationshipsWithNeighbours
                                .isNA.comment
                            : acceptanceOutreach.managingLongTermRelationshipsWithNeighbours &&
                              acceptanceOutreach
                                .managingLongTermRelationshipsWithNeighbours
                                .comment
                            ? acceptanceOutreach
                                .managingLongTermRelationshipsWithNeighbours
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {acceptanceOutreach &&
                          acceptanceOutreach.managingLongTermRelationshipsWithNeighbours &&
                          acceptanceOutreach.managingLongTermRelationshipsWithNeighbours &&
                          acceptanceOutreach
                            .managingLongTermRelationshipsWithNeighbours
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  acceptanceOutreach
                                    .managingLongTermRelationshipsWithNeighbours
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the community manage the development of long-term
                          relationships with local community organisations
                          (faith- based groups, schools, media, law enforcement
                          etc?
                        </td>
                        <td>
                          {acceptanceOutreach &&
                          acceptanceOutreach.managingRelationshipWithLocalCommunity &&
                          acceptanceOutreach
                            .managingRelationshipWithLocalCommunity.value &&
                          acceptanceOutreach
                            .managingRelationshipWithLocalCommunity.isNA &&
                          !acceptanceOutreach
                            .managingRelationshipWithLocalCommunity.isNA.flag
                            ? getScoreFromAnswer(
                                acceptanceOutreach
                                  .managingRelationshipWithLocalCommunity.value
                                  .value
                              )
                            : acceptanceOutreach.managingRelationshipWithLocalCommunity &&
                              acceptanceOutreach
                                .managingRelationshipWithLocalCommunity.isNA &&
                              acceptanceOutreach
                                .managingRelationshipWithLocalCommunity.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {acceptanceOutreach &&
                          acceptanceOutreach.managingRelationshipWithLocalCommunity &&
                          acceptanceOutreach
                            .managingRelationshipWithLocalCommunity.isNA &&
                          acceptanceOutreach
                            .managingRelationshipWithLocalCommunity.isNA
                            ? acceptanceOutreach
                                .managingRelationshipWithLocalCommunity.isNA
                                .comment
                            : acceptanceOutreach.managingRelationshipWithLocalCommunity &&
                              acceptanceOutreach
                                .managingRelationshipWithLocalCommunity.comment
                            ? acceptanceOutreach
                                .managingRelationshipWithLocalCommunity.comment
                            : "-"}
                        </td>
                        <td>
                          {acceptanceOutreach &&
                          acceptanceOutreach.managingRelationshipWithLocalCommunity &&
                          acceptanceOutreach.managingRelationshipWithLocalCommunity &&
                          acceptanceOutreach
                            .managingRelationshipWithLocalCommunity.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  acceptanceOutreach
                                    .managingRelationshipWithLocalCommunity
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Perimeter Protection</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          is there a perimeter wall/fence aorund the entire
                          facility?{" "}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.perimeterProtection &&
                          perimeterProtection.perimeterProtection.value &&
                          perimeterProtection.perimeterProtection.isNA &&
                          !perimeterProtection.perimeterProtection.isNA.flag
                            ? getScoreFromAnswer(
                                perimeterProtection.perimeterProtection.value
                                  .value
                              )
                            : perimeterProtection.perimeterProtection &&
                              perimeterProtection.perimeterProtection.isNA &&
                              perimeterProtection.perimeterProtection.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.perimeterProtection &&
                          perimeterProtection.perimeterProtection.isNA &&
                          perimeterProtection.perimeterProtection.isNA
                            ? perimeterProtection.perimeterProtection.isNA
                                .comment
                            : perimeterProtection.perimeterProtection &&
                              perimeterProtection.perimeterProtection.comment
                            ? perimeterProtection.perimeterProtection.comment
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.perimeterProtection &&
                          perimeterProtection.perimeterProtection &&
                          perimeterProtection.perimeterProtection.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.perimeterProtection.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          is the perimeter wall/fence in a good state of repair?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.goodStateOfRepair &&
                          perimeterProtection.goodStateOfRepair.value &&
                          perimeterProtection.goodStateOfRepair.isNA &&
                          !perimeterProtection.goodStateOfRepair.isNA.flag
                            ? getScoreFromAnswer(
                                perimeterProtection.goodStateOfRepair.value
                                  .value
                              )
                            : perimeterProtection.goodStateOfRepair &&
                              perimeterProtection.goodStateOfRepair.isNA &&
                              perimeterProtection.goodStateOfRepair.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.goodStateOfRepair &&
                          perimeterProtection.goodStateOfRepair.isNA &&
                          perimeterProtection.goodStateOfRepair.isNA
                            ? perimeterProtection.goodStateOfRepair.isNA.comment
                            : perimeterProtection.goodStateOfRepair &&
                              perimeterProtection.goodStateOfRepair.comment
                            ? perimeterProtection.goodStateOfRepair.comment
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.goodStateOfRepair &&
                          perimeterProtection.goodStateOfRepair &&
                          perimeterProtection.goodStateOfRepair.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.goodStateOfRepair.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          is the height of the wall/fence appropriate to the
                          threat?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.heightOfFenceAppropriate &&
                          perimeterProtection.heightOfFenceAppropriate.value &&
                          perimeterProtection.heightOfFenceAppropriate.isNA &&
                          !perimeterProtection.heightOfFenceAppropriate.isNA
                            .flag
                            ? getScoreFromAnswer(
                                perimeterProtection.heightOfFenceAppropriate
                                  .value.value
                              )
                            : perimeterProtection.heightOfFenceAppropriate &&
                              perimeterProtection.heightOfFenceAppropriate
                                .isNA &&
                              perimeterProtection.heightOfFenceAppropriate.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.heightOfFenceAppropriate &&
                          perimeterProtection.heightOfFenceAppropriate.isNA &&
                          perimeterProtection.heightOfFenceAppropriate.isNA
                            ? perimeterProtection.heightOfFenceAppropriate.isNA
                                .comment
                            : perimeterProtection.heightOfFenceAppropriate &&
                              perimeterProtection.heightOfFenceAppropriate
                                .comment
                            ? perimeterProtection.heightOfFenceAppropriate
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.heightOfFenceAppropriate &&
                          perimeterProtection.heightOfFenceAppropriate &&
                          perimeterProtection.heightOfFenceAppropriate.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.heightOfFenceAppropriate
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          is the structural material of the wall/fence
                          appropriate to the threat?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.structuralMaterialAppropriate &&
                          perimeterProtection.structuralMaterialAppropriate
                            .value &&
                          perimeterProtection.structuralMaterialAppropriate
                            .isNA &&
                          !perimeterProtection.structuralMaterialAppropriate
                            .isNA.flag
                            ? getScoreFromAnswer(
                                perimeterProtection
                                  .structuralMaterialAppropriate.value.value
                              )
                            : perimeterProtection &&
                              perimeterProtection.structuralMaterialAppropriate &&
                              perimeterProtection.structuralMaterialAppropriate
                                .isNA &&
                              perimeterProtection.structuralMaterialAppropriate
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.structuralMaterialAppropriate &&
                          perimeterProtection.structuralMaterialAppropriate
                            .isNA &&
                          perimeterProtection.structuralMaterialAppropriate.isNA
                            ? perimeterProtection.structuralMaterialAppropriate
                                .isNA.comment
                            : perimeterProtection.structuralMaterialAppropriate &&
                              perimeterProtection.structuralMaterialAppropriate
                                .comment
                            ? perimeterProtection.structuralMaterialAppropriate
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.structuralMaterialAppropriate &&
                          perimeterProtection.structuralMaterialAppropriate &&
                          perimeterProtection.structuralMaterialAppropriate
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection
                                    .structuralMaterialAppropriate.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the perimeter have good landscaping so trees and
                          bushes can't be used as cover or climb above the wall?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.goodLandscaping &&
                          perimeterProtection.goodLandscaping.value &&
                          perimeterProtection.goodLandscaping.isNA &&
                          !perimeterProtection.goodLandscaping.isNA.flag
                            ? getScoreFromAnswer(
                                perimeterProtection.goodLandscaping.value.value
                              )
                            : perimeterProtection.goodLandscaping &&
                              perimeterProtection.goodLandscaping.isNA &&
                              perimeterProtection.goodLandscaping.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.goodLandscaping &&
                          perimeterProtection.goodLandscaping.isNA &&
                          perimeterProtection.goodLandscaping.isNA
                            ? perimeterProtection.goodLandscaping.isNA.comment
                            : perimeterProtection.goodLandscaping &&
                              perimeterProtection.goodLandscaping.comment
                            ? perimeterProtection.goodLandscaping.comment
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.goodLandscaping &&
                          perimeterProtection.goodLandscaping &&
                          perimeterProtection.goodLandscaping.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.goodLandscaping.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the perimeter have good lighting, giving
                          360-degree coverage without 'dark spots'?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.perimeterLighting &&
                          perimeterProtection.perimeterLighting.value &&
                          perimeterProtection.perimeterLighting.isNA &&
                          !perimeterProtection.perimeterLighting.isNA.flag
                            ? getScoreFromAnswer(
                                perimeterProtection.perimeterLighting.value
                                  .value
                              )
                            : perimeterProtection.perimeterLighting &&
                              perimeterProtection.perimeterLighting.isNA &&
                              perimeterProtection.perimeterLighting.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.perimeterLighting &&
                          perimeterProtection.perimeterLighting.isNA &&
                          perimeterProtection.perimeterLighting.isNA
                            ? perimeterProtection.perimeterLighting.isNA.comment
                            : perimeterProtection.perimeterLighting &&
                              perimeterProtection.perimeterLighting.comment
                            ? perimeterProtection.perimeterLighting.comment
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.perimeterLighting &&
                          perimeterProtection.perimeterLighting &&
                          perimeterProtection.perimeterLighting.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.perimeterLighting.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the exterior of the perimeter have bollards /
                          vehicle barriers to prevent vehicles parking too
                          close?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.exteriorVehicleBarrier &&
                          perimeterProtection.exteriorVehicleBarrier.value &&
                          perimeterProtection.exteriorVehicleBarrier.isNA &&
                          !perimeterProtection.exteriorVehicleBarrier.isNA.flag
                            ? getScoreFromAnswer(
                                perimeterProtection.exteriorVehicleBarrier.value
                                  .value
                              )
                            : perimeterProtection.exteriorVehicleBarrier &&
                              perimeterProtection.exteriorVehicleBarrier.isNA &&
                              perimeterProtection.exteriorVehicleBarrier.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.exteriorVehicleBarrier &&
                          perimeterProtection.exteriorVehicleBarrier.isNA &&
                          perimeterProtection.exteriorVehicleBarrier.isNA
                            ? perimeterProtection.exteriorVehicleBarrier.isNA
                                .comment
                            : perimeterProtection.exteriorVehicleBarrier &&
                              perimeterProtection.exteriorVehicleBarrier.comment
                            ? perimeterProtection.exteriorVehicleBarrier.comment
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.exteriorVehicleBarrier &&
                          perimeterProtection.exteriorVehicleBarrier &&
                          perimeterProtection.exteriorVehicleBarrier.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.exteriorVehicleBarrier
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are the access control and/pr emeergency exit gates
                          designed in a way and made suitable material, to
                          prevent unauthorized access to vehicles and
                          pedestrians?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.emergencyGateDesigned &&
                          perimeterProtection.emergencyGateDesigned.value &&
                          perimeterProtection.emergencyGateDesigned.isNA &&
                          !perimeterProtection.emergencyGateDesigned.isNA.flag
                            ? getScoreFromAnswer(
                                perimeterProtection.emergencyGateDesigned.value
                                  .value
                              )
                            : perimeterProtection.emergencyGateDesigned &&
                              perimeterProtection.emergencyGateDesigned.isNA &&
                              perimeterProtection.emergencyGateDesigned.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.emergencyGateDesigned &&
                          perimeterProtection.emergencyGateDesigned.isNA &&
                          perimeterProtection.emergencyGateDesigned.isNA
                            ? perimeterProtection.emergencyGateDesigned.isNA
                                .comment
                            : perimeterProtection.emergencyGateDesigned &&
                              perimeterProtection.emergencyGateDesigned.comment
                            ? perimeterProtection.emergencyGateDesigned.comment
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.emergencyGateDesigned &&
                          perimeterProtection.emergencyGateDesigned &&
                          perimeterProtection.emergencyGateDesigned.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.emergencyGateDesigned
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the perimeter have multiple, clearly marked and
                          accessible entery and exit points, to facilitate the
                          movement of people and vehicles?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.accessibleEntryExitPoints &&
                          perimeterProtection.accessibleEntryExitPoints.value &&
                          perimeterProtection.accessibleEntryExitPoints.isNA &&
                          !perimeterProtection.accessibleEntryExitPoints.isNA
                            .flag
                            ? getScoreFromAnswer(
                                perimeterProtection.accessibleEntryExitPoints
                                  .value.value
                              )
                            : perimeterProtection.accessibleEntryExitPoints &&
                              perimeterProtection.accessibleEntryExitPoints
                                .isNA &&
                              perimeterProtection.accessibleEntryExitPoints.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.accessibleEntryExitPoints &&
                          perimeterProtection.accessibleEntryExitPoints.isNA &&
                          perimeterProtection.accessibleEntryExitPoints.isNA
                            ? perimeterProtection.accessibleEntryExitPoints.isNA
                                .comment
                            : perimeterProtection.accessibleEntryExitPoints &&
                              perimeterProtection.accessibleEntryExitPoints
                                .comment
                            ? perimeterProtection.accessibleEntryExitPoints
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.accessibleEntryExitPoints &&
                          perimeterProtection.accessibleEntryExitPoints &&
                          perimeterProtection.accessibleEntryExitPoints
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.accessibleEntryExitPoints
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are there any enough security personnel/ volunteers
                          deployed to control and monitor the perimeter?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.securityPersonnelDeployed &&
                          perimeterProtection.securityPersonnelDeployed.value &&
                          perimeterProtection.securityPersonnelDeployed.isNA &&
                          !perimeterProtection.securityPersonnelDeployed.isNA
                            .flag
                            ? getScoreFromAnswer(
                                perimeterProtection.securityPersonnelDeployed
                                  .value.value
                              )
                            : perimeterProtection.securityPersonnelDeployed &&
                              perimeterProtection.securityPersonnelDeployed
                                .isNA &&
                              perimeterProtection.securityPersonnelDeployed.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.securityPersonnelDeployed &&
                          perimeterProtection.securityPersonnelDeployed.isNA &&
                          perimeterProtection.securityPersonnelDeployed.isNA
                            ? perimeterProtection.securityPersonnelDeployed.isNA
                                .comment
                            : perimeterProtection.securityPersonnelDeployed &&
                              perimeterProtection.securityPersonnelDeployed
                                .comment
                            ? perimeterProtection.securityPersonnelDeployed
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.securityPersonnelDeployed &&
                          perimeterProtection.securityPersonnelDeployed &&
                          perimeterProtection.securityPersonnelDeployed
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.securityPersonnelDeployed
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the perimeter regularly have security patrols to
                          monitor the perimeter?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.securityPatrols &&
                          perimeterProtection.securityPatrols.value &&
                          perimeterProtection.securityPatrols.isNA &&
                          !perimeterProtection.securityPatrols.isNA.flag
                            ? getScoreFromAnswer(
                                perimeterProtection.securityPatrols.value.value
                              )
                            : perimeterProtection.securityPatrols &&
                              perimeterProtection.securityPatrols.isNA &&
                              perimeterProtection.securityPatrols.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.securityPatrols &&
                          perimeterProtection.securityPatrols.isNA &&
                          perimeterProtection.securityPatrols.isNA
                            ? perimeterProtection.securityPatrols.isNA.comment
                            : perimeterProtection.securityPatrols &&
                              perimeterProtection.securityPatrols.comment
                            ? perimeterProtection.securityPatrols.comment
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.securityPatrols &&
                          perimeterProtection.securityPatrols &&
                          perimeterProtection.securityPatrols.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.securityPatrols.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are guard / patrol dogs utilised on perimeter patrols?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.guardPatrolDogs &&
                          perimeterProtection.guardPatrolDogs.value &&
                          perimeterProtection.guardPatrolDogs.isNA &&
                          !perimeterProtection.guardPatrolDogs.isNA.flag
                            ? getScoreFromAnswer(
                                perimeterProtection.guardPatrolDogs.value.value
                              )
                            : perimeterProtection.guardPatrolDogs &&
                              perimeterProtection.guardPatrolDogs.isNA &&
                              perimeterProtection.guardPatrolDogs.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.guardPatrolDogs &&
                          perimeterProtection.guardPatrolDogs.isNA &&
                          perimeterProtection.guardPatrolDogs.isNA
                            ? perimeterProtection.guardPatrolDogs.isNA.comment
                            : perimeterProtection.guardPatrolDogs &&
                              perimeterProtection.guardPatrolDogs.comment
                            ? perimeterProtection.guardPatrolDogs.comment
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.guardPatrolDogs &&
                          perimeterProtection.guardPatrolDogs &&
                          perimeterProtection.guardPatrolDogs.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.guardPatrolDogs.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are internal Parking Area adequetly controlled and
                          monitored?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.parkingControlledMonitored &&
                          perimeterProtection.parkingControlledMonitored
                            .value &&
                          perimeterProtection.parkingControlledMonitored.isNA &&
                          !perimeterProtection.parkingControlledMonitored.isNA
                            .flag
                            ? getScoreFromAnswer(
                                perimeterProtection.parkingControlledMonitored
                                  .value.value
                              )
                            : perimeterProtection.parkingControlledMonitored &&
                              perimeterProtection.parkingControlledMonitored
                                .isNA &&
                              perimeterProtection.parkingControlledMonitored
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.parkingControlledMonitored &&
                          perimeterProtection.parkingControlledMonitored.isNA &&
                          perimeterProtection.parkingControlledMonitored.isNA
                            ? perimeterProtection.parkingControlledMonitored
                                .isNA.comment
                            : perimeterProtection.parkingControlledMonitored &&
                              perimeterProtection.parkingControlledMonitored
                                .comment
                            ? perimeterProtection.parkingControlledMonitored
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.parkingControlledMonitored &&
                          perimeterProtection.parkingControlledMonitored &&
                          perimeterProtection.parkingControlledMonitored
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.parkingControlledMonitored
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Access Control</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Does the Jamatkhana have effective written access
                          control procedures that are rigidly followed?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.accessControlProcedures &&
                          accessControl.accessControlProcedures.value &&
                          accessControl.accessControlProcedures.isNA &&
                          !accessControl.accessControlProcedures.isNA.flag
                            ? getScoreFromAnswer(
                                accessControl.accessControlProcedures.value
                                  .value
                              )
                            : accessControl.accessControlProcedures &&
                              accessControl.accessControlProcedures.isNA &&
                              accessControl.accessControlProcedures.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.accessControlProcedures &&
                          accessControl.accessControlProcedures.isNA &&
                          accessControl.accessControlProcedures.isNA
                            ? accessControl.accessControlProcedures.isNA.comment
                            : accessControl.accessControlProcedures &&
                              accessControl.accessControlProcedures.comment
                            ? accessControl.accessControlProcedures.comment
                            : "-"}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.accessControlProcedures &&
                          accessControl.accessControlProcedures &&
                          accessControl.accessControlProcedures.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl.accessControlProcedures.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Do the access control procedures facilitate smooth and
                          secure access/egress for the Jamat during congregation
                          times?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.accessControlProceduresDuringCongestedPeriods &&
                          accessControl
                            .accessControlProceduresDuringCongestedPeriods
                            .value &&
                          accessControl
                            .accessControlProceduresDuringCongestedPeriods
                            .isNA &&
                          !accessControl
                            .accessControlProceduresDuringCongestedPeriods.isNA
                            .flag
                            ? getScoreFromAnswer(
                                accessControl
                                  .accessControlProceduresDuringCongestedPeriods
                                  .value.value
                              )
                            : accessControl.accessControlProceduresDuringCongestedPeriods &&
                              accessControl
                                .accessControlProceduresDuringCongestedPeriods
                                .isNA &&
                              accessControl
                                .accessControlProceduresDuringCongestedPeriods
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.accessControlProceduresDuringCongestedPeriods &&
                          accessControl
                            .accessControlProceduresDuringCongestedPeriods
                            .isNA &&
                          accessControl
                            .accessControlProceduresDuringCongestedPeriods.isNA
                            ? accessControl
                                .accessControlProceduresDuringCongestedPeriods
                                .isNA.comment
                            : accessControl.accessControlProceduresDuringCongestedPeriods &&
                              accessControl
                                .accessControlProceduresDuringCongestedPeriods
                                .comment
                            ? accessControl
                                .accessControlProceduresDuringCongestedPeriods
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.accessControlProceduresDuringCongestedPeriods &&
                          accessControl.accessControlProceduresDuringCongestedPeriods &&
                          accessControl
                            .accessControlProceduresDuringCongestedPeriods
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .accessControlProceduresDuringCongestedPeriods
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Do the access control procedures facilitate smooth and
                          secure access/egress for the Jamat and visitors
                          outside of congregation times?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.accessControlProceduresOutsideCongestedPeriods &&
                          accessControl
                            .accessControlProceduresOutsideCongestedPeriods
                            .value &&
                          accessControl
                            .accessControlProceduresOutsideCongestedPeriods
                            .isNA &&
                          !accessControl
                            .accessControlProceduresOutsideCongestedPeriods.isNA
                            .flag
                            ? getScoreFromAnswer(
                                accessControl
                                  .accessControlProceduresOutsideCongestedPeriods
                                  .value.value
                              )
                            : accessControl.accessControlProceduresOutsideCongestedPeriods &&
                              accessControl
                                .accessControlProceduresOutsideCongestedPeriods
                                .isNA &&
                              accessControl
                                .accessControlProceduresOutsideCongestedPeriods
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.accessControlProceduresOutsideCongestedPeriods &&
                          accessControl
                            .accessControlProceduresOutsideCongestedPeriods
                            .isNA &&
                          accessControl
                            .accessControlProceduresOutsideCongestedPeriods.isNA
                            ? accessControl
                                .accessControlProceduresOutsideCongestedPeriods
                                .isNA.comment
                            : accessControl.accessControlProceduresOutsideCongestedPeriods &&
                              accessControl
                                .accessControlProceduresOutsideCongestedPeriods
                                .comment
                            ? accessControl
                                .accessControlProceduresOutsideCongestedPeriods
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.accessControlProceduresOutsideCongestedPeriods &&
                          accessControl.accessControlProceduresOutsideCongestedPeriods &&
                          accessControl
                            .accessControlProceduresOutsideCongestedPeriods
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .accessControlProceduresOutsideCongestedPeriods
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the Jamatkhana have seprate pedestrian control
                          point to avoid pedestrian and vehicle collision?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.sepratePedestrianControl &&
                          accessControl.sepratePedestrianControl.value &&
                          accessControl.sepratePedestrianControl.isNA &&
                          !accessControl.sepratePedestrianControl.isNA.flag
                            ? getScoreFromAnswer(
                                accessControl.sepratePedestrianControl.value
                                  .value
                              )
                            : accessControl.sepratePedestrianControl &&
                              accessControl.sepratePedestrianControl.isNA &&
                              accessControl.sepratePedestrianControl.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.sepratePedestrianControl &&
                          accessControl.sepratePedestrianControl.isNA &&
                          accessControl.sepratePedestrianControl.isNA
                            ? accessControl.sepratePedestrianControl.isNA
                                .comment
                            : accessControl.sepratePedestrianControl &&
                              accessControl.sepratePedestrianControl.comment
                            ? accessControl.sepratePedestrianControl.comment
                            : "-"}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.sepratePedestrianControl &&
                          accessControl.sepratePedestrianControl &&
                          accessControl.sepratePedestrianControl.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl.sepratePedestrianControl.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are ID checks and person / vehicle searches for
                          congregation members conducted with appropriate
                          thoroughness for the context?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.personAndVehicleCHecks &&
                          accessControl.personAndVehicleCHecks.value &&
                          accessControl.personAndVehicleCHecks.isNA &&
                          !accessControl.personAndVehicleCHecks.isNA.flag
                            ? getScoreFromAnswer(
                                accessControl.personAndVehicleCHecks.value.value
                              )
                            : accessControl.personAndVehicleCHecks &&
                              accessControl.personAndVehicleCHecks.isNA &&
                              accessControl.personAndVehicleCHecks.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.personAndVehicleCHecks &&
                          accessControl.personAndVehicleCHecks.isNA &&
                          accessControl.personAndVehicleCHecks.isNA
                            ? accessControl.personAndVehicleCHecks.isNA.comment
                            : accessControl.personAndVehicleCHecks &&
                              accessControl.personAndVehicleCHecks.comment
                            ? accessControl.personAndVehicleCHecks.comment
                            : "-"}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.personAndVehicleCHecks &&
                          accessControl.personAndVehicleCHecks &&
                          accessControl.personAndVehicleCHecks.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl.personAndVehicleCHecks.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are ID checks, purpose of visit checks and person /
                          vehicle searches for non-congregation members
                          conducted with appropriate thoroughness for the
                          context?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.idChecks &&
                          accessControl.idChecks.value &&
                          accessControl.idChecks.isNA &&
                          !accessControl.idChecks.isNA.flag
                            ? getScoreFromAnswer(
                                accessControl.idChecks.value.value
                              )
                            : accessControl.idChecks &&
                              accessControl.idChecks.isNA &&
                              accessControl.idChecks.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.idChecks &&
                          accessControl.idChecks.isNA &&
                          accessControl.idChecks.isNA
                            ? accessControl.idChecks.isNA.comment
                            : accessControl.idChecks &&
                              accessControl.idChecks.comment
                            ? accessControl.idChecks.comment
                            : "-"}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.idChecks &&
                          accessControl.idChecks &&
                          accessControl.idChecks.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(accessControl.idChecks.image),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are control measures in place to ensure
                          material/supolies comina into the Jamatkhana. and
                          material/supplies being removed. are controlled and
                          recorded?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.checksForMaterialSupply &&
                          accessControl.checksForMaterialSupply.value &&
                          accessControl.checksForMaterialSupply.isNA &&
                          !accessControl.checksForMaterialSupply.isNA.flag
                            ? getScoreFromAnswer(
                                accessControl.checksForMaterialSupply.value
                                  .value
                              )
                            : accessControl.checksForMaterialSupply &&
                              accessControl.checksForMaterialSupply.isNA &&
                              accessControl.checksForMaterialSupply.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.checksForMaterialSupply &&
                          accessControl.checksForMaterialSupply.isNA &&
                          accessControl.checksForMaterialSupply.isNA
                            ? accessControl.checksForMaterialSupply.isNA.comment
                            : accessControl.checksForMaterialSupply &&
                              accessControl.checksForMaterialSupply.comment
                            ? accessControl.checksForMaterialSupply.comment
                            : "-"}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.checksForMaterialSupply &&
                          accessControl.checksForMaterialSupply &&
                          accessControl.checksForMaterialSupply.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl.checksForMaterialSupply.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Structural Resistance and Protection</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          If blast is an identified threat, is there adequate
                          distance (stand-off) between the Jamatkhana and the
                          external perimeter?
                        </td>
                        <td>
                          {StructuralResistanceAndProtection &&
                          StructuralResistanceAndProtection.adequateDistanceBetweenJamatkhanaAndExternalPremesis &&
                          StructuralResistanceAndProtection
                            .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                            .value &&
                          StructuralResistanceAndProtection
                            .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                            .isNA &&
                          !StructuralResistanceAndProtection
                            .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                            .isNA.flag
                            ? getScoreFromAnswer(
                                StructuralResistanceAndProtection
                                  .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                                  .value.value
                              )
                            : StructuralResistanceAndProtection.adequateDistanceBetweenJamatkhanaAndExternalPremesis &&
                              StructuralResistanceAndProtection
                                .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                                .isNA &&
                              StructuralResistanceAndProtection
                                .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {StructuralResistanceAndProtection &&
                          StructuralResistanceAndProtection.adequateDistanceBetweenJamatkhanaAndExternalPremesis &&
                          StructuralResistanceAndProtection
                            .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                            .isNA &&
                          StructuralResistanceAndProtection
                            .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                            .isNA
                            ? StructuralResistanceAndProtection
                                .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                                .isNA.comment
                            : StructuralResistanceAndProtection.adequateDistanceBetweenJamatkhanaAndExternalPremesis &&
                              StructuralResistanceAndProtection
                                .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                                .comment
                            ? StructuralResistanceAndProtection
                                .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {StructuralResistanceAndProtection &&
                          StructuralResistanceAndProtection.adequateDistanceBetweenJamatkhanaAndExternalPremesis &&
                          StructuralResistanceAndProtection.adequateDistanceBetweenJamatkhanaAndExternalPremesis &&
                          StructuralResistanceAndProtection
                            .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  StructuralResistanceAndProtection
                                    .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Is the Jamatkhana constructed with robust materials
                          (concrete, brick, steel etc.)?
                        </td>
                        <td>
                          {StructuralResistanceAndProtection &&
                          StructuralResistanceAndProtection.jamatkahanConstructedWithRobustMaterial &&
                          StructuralResistanceAndProtection
                            .jamatkahanConstructedWithRobustMaterial.value &&
                          StructuralResistanceAndProtection
                            .jamatkahanConstructedWithRobustMaterial.isNA &&
                          !StructuralResistanceAndProtection
                            .jamatkahanConstructedWithRobustMaterial.isNA.flag
                            ? getScoreFromAnswer(
                                StructuralResistanceAndProtection
                                  .jamatkahanConstructedWithRobustMaterial.value
                                  .value
                              )
                            : StructuralResistanceAndProtection.jamatkahanConstructedWithRobustMaterial &&
                              StructuralResistanceAndProtection
                                .jamatkahanConstructedWithRobustMaterial.isNA &&
                              StructuralResistanceAndProtection
                                .jamatkahanConstructedWithRobustMaterial.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {StructuralResistanceAndProtection &&
                          StructuralResistanceAndProtection.jamatkahanConstructedWithRobustMaterial &&
                          StructuralResistanceAndProtection
                            .jamatkahanConstructedWithRobustMaterial.isNA &&
                          StructuralResistanceAndProtection
                            .jamatkahanConstructedWithRobustMaterial.isNA
                            ? StructuralResistanceAndProtection
                                .jamatkahanConstructedWithRobustMaterial.isNA
                                .comment
                            : StructuralResistanceAndProtection.jamatkahanConstructedWithRobustMaterial &&
                              StructuralResistanceAndProtection
                                .jamatkahanConstructedWithRobustMaterial.comment
                            ? StructuralResistanceAndProtection
                                .jamatkahanConstructedWithRobustMaterial.comment
                            : "-"}
                        </td>
                        <td>
                          {StructuralResistanceAndProtection &&
                          StructuralResistanceAndProtection.jamatkahanConstructedWithRobustMaterial &&
                          StructuralResistanceAndProtection.jamatkahanConstructedWithRobustMaterial &&
                          StructuralResistanceAndProtection
                            .jamatkahanConstructedWithRobustMaterial.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  StructuralResistanceAndProtection
                                    .jamatkahanConstructedWithRobustMaterial
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Is the lamatkhana seismicallv reinforced to withstand
                          an earthguake?
                        </td>
                        <td>
                          {StructuralResistanceAndProtection &&
                          StructuralResistanceAndProtection.adequateToWithstandEarthquake &&
                          StructuralResistanceAndProtection
                            .adequateToWithstandEarthquake.value &&
                          StructuralResistanceAndProtection
                            .adequateToWithstandEarthquake.isNA &&
                          !StructuralResistanceAndProtection
                            .adequateToWithstandEarthquake.isNA.flag
                            ? getScoreFromAnswer(
                                StructuralResistanceAndProtection
                                  .adequateToWithstandEarthquake.value.value
                              )
                            : StructuralResistanceAndProtection.adequateToWithstandEarthquake &&
                              StructuralResistanceAndProtection
                                .adequateToWithstandEarthquake.isNA &&
                              StructuralResistanceAndProtection
                                .adequateToWithstandEarthquake.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {StructuralResistanceAndProtection &&
                          StructuralResistanceAndProtection.adequateToWithstandEarthquake &&
                          StructuralResistanceAndProtection
                            .adequateToWithstandEarthquake.isNA &&
                          StructuralResistanceAndProtection
                            .adequateToWithstandEarthquake.isNA
                            ? StructuralResistanceAndProtection
                                .adequateToWithstandEarthquake.isNA.comment
                            : StructuralResistanceAndProtection.adequateToWithstandEarthquake &&
                              StructuralResistanceAndProtection
                                .adequateToWithstandEarthquake.comment
                            ? StructuralResistanceAndProtection
                                .adequateToWithstandEarthquake.comment
                            : "-"}
                        </td>
                        <td>
                          {StructuralResistanceAndProtection &&
                          StructuralResistanceAndProtection.adequateToWithstandEarthquake &&
                          StructuralResistanceAndProtection.adequateToWithstandEarthquake &&
                          StructuralResistanceAndProtection
                            .adequateToWithstandEarthquake.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  StructuralResistanceAndProtection
                                    .adequateToWithstandEarthquake.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the Jamatkhana incorporate blast protection
                          measures (e.g. blast walls or blast film on the
                          windows)?
                        </td>
                        <td>
                          {StructuralResistanceAndProtection &&
                          StructuralResistanceAndProtection.blastProtectionMeasure &&
                          StructuralResistanceAndProtection
                            .blastProtectionMeasure.value &&
                          StructuralResistanceAndProtection
                            .blastProtectionMeasure.isNA &&
                          !StructuralResistanceAndProtection
                            .blastProtectionMeasure.isNA.flag
                            ? getScoreFromAnswer(
                                StructuralResistanceAndProtection
                                  .blastProtectionMeasure.value.value
                              )
                            : StructuralResistanceAndProtection.blastProtectionMeasure &&
                              StructuralResistanceAndProtection
                                .blastProtectionMeasure.isNA &&
                              StructuralResistanceAndProtection
                                .blastProtectionMeasure.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {StructuralResistanceAndProtection &&
                          StructuralResistanceAndProtection.blastProtectionMeasure &&
                          StructuralResistanceAndProtection
                            .blastProtectionMeasure.isNA &&
                          StructuralResistanceAndProtection
                            .blastProtectionMeasure.isNA
                            ? StructuralResistanceAndProtection
                                .blastProtectionMeasure.isNA.comment
                            : StructuralResistanceAndProtection.blastProtectionMeasure &&
                              StructuralResistanceAndProtection
                                .blastProtectionMeasure.comment
                            ? StructuralResistanceAndProtection
                                .blastProtectionMeasure.comment
                            : "-"}
                        </td>
                        <td>
                          {StructuralResistanceAndProtection &&
                          StructuralResistanceAndProtection.blastProtectionMeasure &&
                          StructuralResistanceAndProtection.blastProtectionMeasure &&
                          StructuralResistanceAndProtection
                            .blastProtectionMeasure.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  StructuralResistanceAndProtection
                                    .blastProtectionMeasure.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Jamatkhana Security</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Are all windows secured, including with bars where
                          appropriate?
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.windowsSecuredWithBat &&
                          jamatkhanaSecurity.windowsSecuredWithBat.value &&
                          jamatkhanaSecurity.windowsSecuredWithBat.isNA &&
                          !jamatkhanaSecurity.windowsSecuredWithBat.isNA.flag
                            ? getScoreFromAnswer(
                                jamatkhanaSecurity.windowsSecuredWithBat.value
                                  .value
                              )
                            : jamatkhanaSecurity.windowsSecuredWithBat &&
                              jamatkhanaSecurity.windowsSecuredWithBat.isNA &&
                              jamatkhanaSecurity.windowsSecuredWithBat.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.windowsSecuredWithBat &&
                          jamatkhanaSecurity.windowsSecuredWithBat.isNA &&
                          jamatkhanaSecurity.windowsSecuredWithBat.isNA
                            ? jamatkhanaSecurity.windowsSecuredWithBat.isNA
                                .comment
                            : jamatkhanaSecurity.windowsSecuredWithBat &&
                              jamatkhanaSecurity.windowsSecuredWithBat.comment
                            ? jamatkhanaSecurity.windowsSecuredWithBat.comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.windowsSecuredWithBat &&
                          jamatkhanaSecurity.windowsSecuredWithBat &&
                          jamatkhanaSecurity.windowsSecuredWithBat.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaSecurity.windowsSecuredWithBat.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are all doors secured, or able to be secured,
                          throughout the premises?
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.doorSecured &&
                          jamatkhanaSecurity.doorSecured.value &&
                          jamatkhanaSecurity.doorSecured.isNA &&
                          !jamatkhanaSecurity.doorSecured.isNA.flag
                            ? getScoreFromAnswer(
                                jamatkhanaSecurity.doorSecured.value.value
                              )
                            : jamatkhanaSecurity.doorSecured &&
                              jamatkhanaSecurity.doorSecured.isNA &&
                              jamatkhanaSecurity.doorSecured.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.doorSecured &&
                          jamatkhanaSecurity.doorSecured.isNA &&
                          jamatkhanaSecurity.doorSecured.isNA
                            ? jamatkhanaSecurity.doorSecured.isNA.comment
                            : jamatkhanaSecurity.doorSecured &&
                              jamatkhanaSecurity.doorSecured.comment
                            ? jamatkhanaSecurity.doorSecured.comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.doorSecured &&
                          jamatkhanaSecurity.doorSecured &&
                          jamatkhanaSecurity.doorSecured.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaSecurity.doorSecured.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Is the Jamatkhana protected against unauthorised
                          access from the roof?
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.protectedAgainstUNAuthAccessFromRoof &&
                          jamatkhanaSecurity
                            .protectedAgainstUNAuthAccessFromRoof.value &&
                          jamatkhanaSecurity
                            .protectedAgainstUNAuthAccessFromRoof.isNA &&
                          !jamatkhanaSecurity
                            .protectedAgainstUNAuthAccessFromRoof.isNA.flag
                            ? getScoreFromAnswer(
                                jamatkhanaSecurity
                                  .protectedAgainstUNAuthAccessFromRoof.value
                                  .value
                              )
                            : jamatkhanaSecurity.protectedAgainstUNAuthAccessFromRoof &&
                              jamatkhanaSecurity
                                .protectedAgainstUNAuthAccessFromRoof.isNA &&
                              jamatkhanaSecurity
                                .protectedAgainstUNAuthAccessFromRoof.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.protectedAgainstUNAuthAccessFromRoof &&
                          jamatkhanaSecurity
                            .protectedAgainstUNAuthAccessFromRoof.isNA &&
                          jamatkhanaSecurity
                            .protectedAgainstUNAuthAccessFromRoof.isNA
                            ? jamatkhanaSecurity
                                .protectedAgainstUNAuthAccessFromRoof.isNA
                                .comment
                            : jamatkhanaSecurity.protectedAgainstUNAuthAccessFromRoof &&
                              jamatkhanaSecurity
                                .protectedAgainstUNAuthAccessFromRoof.comment
                            ? jamatkhanaSecurity
                                .protectedAgainstUNAuthAccessFromRoof.comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.protectedAgainstUNAuthAccessFromRoof &&
                          jamatkhanaSecurity.protectedAgainstUNAuthAccessFromRoof &&
                          jamatkhanaSecurity
                            .protectedAgainstUNAuthAccessFromRoof.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaSecurity
                                    .protectedAgainstUNAuthAccessFromRoof.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Is the Jamatkhana protected against unauthorised
                          access from the basement?
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.protectedAgainstUNAuthAccessFromBasement &&
                          jamatkhanaSecurity
                            .protectedAgainstUNAuthAccessFromBasement.value &&
                          jamatkhanaSecurity
                            .protectedAgainstUNAuthAccessFromBasement.isNA &&
                          !jamatkhanaSecurity
                            .protectedAgainstUNAuthAccessFromBasement.isNA.flag
                            ? getScoreFromAnswer(
                                jamatkhanaSecurity
                                  .protectedAgainstUNAuthAccessFromBasement
                                  .value.value
                              )
                            : jamatkhanaSecurity.protectedAgainstUNAuthAccessFromBasement &&
                              jamatkhanaSecurity
                                .protectedAgainstUNAuthAccessFromBasement
                                .isNA &&
                              jamatkhanaSecurity
                                .protectedAgainstUNAuthAccessFromBasement.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.protectedAgainstUNAuthAccessFromBasement &&
                          jamatkhanaSecurity
                            .protectedAgainstUNAuthAccessFromBasement.isNA &&
                          jamatkhanaSecurity
                            .protectedAgainstUNAuthAccessFromBasement.isNA
                            ? jamatkhanaSecurity
                                .protectedAgainstUNAuthAccessFromBasement.isNA
                                .comment
                            : jamatkhanaSecurity.protectedAgainstUNAuthAccessFromBasement &&
                              jamatkhanaSecurity
                                .protectedAgainstUNAuthAccessFromBasement
                                .comment
                            ? jamatkhanaSecurity
                                .protectedAgainstUNAuthAccessFromBasement
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.protectedAgainstUNAuthAccessFromBasement &&
                          jamatkhanaSecurity.protectedAgainstUNAuthAccessFromBasement &&
                          jamatkhanaSecurity
                            .protectedAgainstUNAuthAccessFromBasement.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaSecurity
                                    .protectedAgainstUNAuthAccessFromBasement
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are valuable assets (IT servers, safes, IT equipment
                          etc.) adequately secured?
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.assetsAdequatelySecured &&
                          jamatkhanaSecurity.assetsAdequatelySecured.value &&
                          jamatkhanaSecurity.assetsAdequatelySecured.isNA &&
                          !jamatkhanaSecurity.assetsAdequatelySecured.isNA.flag
                            ? getScoreFromAnswer(
                                jamatkhanaSecurity.assetsAdequatelySecured.value
                                  .value
                              )
                            : jamatkhanaSecurity.assetsAdequatelySecured &&
                              jamatkhanaSecurity.assetsAdequatelySecured.isNA &&
                              jamatkhanaSecurity.assetsAdequatelySecured.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.assetsAdequatelySecured &&
                          jamatkhanaSecurity.assetsAdequatelySecured.isNA &&
                          jamatkhanaSecurity.assetsAdequatelySecured.isNA
                            ? jamatkhanaSecurity.assetsAdequatelySecured.isNA
                                .comment
                            : jamatkhanaSecurity.assetsAdequatelySecured &&
                              jamatkhanaSecurity.assetsAdequatelySecured.comment
                            ? jamatkhanaSecurity.assetsAdequatelySecured.comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.assetsAdequatelySecured &&
                          jamatkhanaSecurity.assetsAdequatelySecured &&
                          jamatkhanaSecurity.assetsAdequatelySecured.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaSecurity.assetsAdequatelySecured
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          If there Is a parking area in or under the Jamatkhana,
                          Is It protected by effective access control and search
                          procedures:
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.parkingAreaSecured &&
                          jamatkhanaSecurity.parkingAreaSecured.value &&
                          jamatkhanaSecurity.parkingAreaSecured.isNA &&
                          !jamatkhanaSecurity.parkingAreaSecured.isNA.flag
                            ? getScoreFromAnswer(
                                jamatkhanaSecurity.parkingAreaSecured.value
                                  .value
                              )
                            : jamatkhanaSecurity.parkingAreaSecured &&
                              jamatkhanaSecurity.parkingAreaSecured.isNA &&
                              jamatkhanaSecurity.parkingAreaSecured.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.parkingAreaSecured &&
                          jamatkhanaSecurity.parkingAreaSecured.isNA &&
                          jamatkhanaSecurity.parkingAreaSecured.isNA
                            ? jamatkhanaSecurity.parkingAreaSecured.isNA.comment
                            : jamatkhanaSecurity.parkingAreaSecured &&
                              jamatkhanaSecurity.parkingAreaSecured.comment
                            ? jamatkhanaSecurity.parkingAreaSecured.comment
                            : "-"}
                        </td>
                        <td>
                          {jamatkhanaSecurity &&
                          jamatkhanaSecurity.parkingAreaSecured &&
                          jamatkhanaSecurity.parkingAreaSecured &&
                          jamatkhanaSecurity.parkingAreaSecured.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaSecurity.parkingAreaSecured.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Lockdown / Shelter in Place</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Is it possible to lockdown the Jamatkhana in times of
                          emergency?
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.canLockDownJamatkhana &&
                          lockdown.canLockDownJamatkhana.value &&
                          lockdown.canLockDownJamatkhana.isNA &&
                          !lockdown.canLockDownJamatkhana.isNA.flag
                            ? getScoreFromAnswer(
                                lockdown.canLockDownJamatkhana.value.value
                              )
                            : lockdown.canLockDownJamatkhana &&
                              lockdown.canLockDownJamatkhana.isNA &&
                              lockdown.canLockDownJamatkhana.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.canLockDownJamatkhana &&
                          lockdown.canLockDownJamatkhana.isNA &&
                          lockdown.canLockDownJamatkhana.isNA
                            ? lockdown.canLockDownJamatkhana.isNA.comment
                            : lockdown.canLockDownJamatkhana &&
                              lockdown.canLockDownJamatkhana.comment
                            ? lockdown.canLockDownJamatkhana.comment
                            : "-"}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.canLockDownJamatkhana &&
                          lockdown.canLockDownJamatkhana &&
                          lockdown.canLockDownJamatkhana.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  lockdown.canLockDownJamatkhana.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Does the Jamatkhana have an effective, written
                          Lockdown / Shelter-in-Place Plan?
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.lockdownPlan &&
                          lockdown.lockdownPlan.value &&
                          lockdown.lockdownPlan.isNA &&
                          !lockdown.lockdownPlan.isNA.flag
                            ? getScoreFromAnswer(
                                lockdown.lockdownPlan.value.value
                              )
                            : lockdown.lockdownPlan &&
                              lockdown.lockdownPlan.isNA &&
                              lockdown.lockdownPlan.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.lockdownPlan &&
                          lockdown.lockdownPlan.isNA &&
                          lockdown.lockdownPlan.isNA
                            ? lockdown.lockdownPlan.isNA.comment
                            : lockdown.lockdownPlan &&
                              lockdown.lockdownPlan.comment
                            ? lockdown.lockdownPlan.comment
                            : "-"}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.lockdownPlan &&
                          lockdown.lockdownPlan &&
                          lockdown.lockdownPlan.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(lockdown.lockdownPlan.image),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Does the lockdown / shelter-in-place plan include
                          roles and responsibilities, method of initiating the
                          lockdown, methods for securing the Jamatkhana and
                          location of safe rooms?
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.rolesAndResponsibilitiesInLockdown &&
                          lockdown.rolesAndResponsibilitiesInLockdown.value &&
                          lockdown.rolesAndResponsibilitiesInLockdown.isNA &&
                          !lockdown.rolesAndResponsibilitiesInLockdown.isNA.flag
                            ? getScoreFromAnswer(
                                lockdown.rolesAndResponsibilitiesInLockdown
                                  .value.value
                              )
                            : lockdown.rolesAndResponsibilitiesInLockdown &&
                              lockdown.rolesAndResponsibilitiesInLockdown
                                .isNA &&
                              lockdown.rolesAndResponsibilitiesInLockdown.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.rolesAndResponsibilitiesInLockdown &&
                          lockdown.rolesAndResponsibilitiesInLockdown.isNA &&
                          lockdown.rolesAndResponsibilitiesInLockdown.isNA
                            ? lockdown.rolesAndResponsibilitiesInLockdown.isNA
                                .comment
                            : lockdown.rolesAndResponsibilitiesInLockdown &&
                              lockdown.rolesAndResponsibilitiesInLockdown
                                .comment
                            ? lockdown.rolesAndResponsibilitiesInLockdown
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.rolesAndResponsibilitiesInLockdown &&
                          lockdown.rolesAndResponsibilitiesInLockdown &&
                          lockdown.rolesAndResponsibilitiesInLockdown.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  lockdown.rolesAndResponsibilitiesInLockdown
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Have all staff / volunteers been trained on the
                          Lockdown / Shelter-in-Place Plan?
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.volunteetPlannedForLockdown &&
                          lockdown.volunteetPlannedForLockdown.value &&
                          lockdown.volunteetPlannedForLockdown.isNA &&
                          !lockdown.volunteetPlannedForLockdown.isNA.flag
                            ? getScoreFromAnswer(
                                lockdown.volunteetPlannedForLockdown.value.value
                              )
                            : lockdown.volunteetPlannedForLockdown &&
                              lockdown.volunteetPlannedForLockdown.isNA &&
                              lockdown.volunteetPlannedForLockdown.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.volunteetPlannedForLockdown &&
                          lockdown.volunteetPlannedForLockdown.isNA &&
                          lockdown.volunteetPlannedForLockdown.isNA
                            ? lockdown.volunteetPlannedForLockdown.isNA.comment
                            : lockdown.volunteetPlannedForLockdown &&
                              lockdown.volunteetPlannedForLockdown.comment
                            ? lockdown.volunteetPlannedForLockdown.comment
                            : "-"}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.volunteetPlannedForLockdown &&
                          lockdown.volunteetPlannedForLockdown &&
                          lockdown.volunteetPlannedForLockdown.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  lockdown.volunteetPlannedForLockdown.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Have safe spaces been identified in the premises and
                          are they fit for purpose?
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.safeSpaceIdentified &&
                          lockdown.safeSpaceIdentified.value &&
                          lockdown.safeSpaceIdentified.isNA &&
                          !lockdown.safeSpaceIdentified.isNA.flag
                            ? getScoreFromAnswer(
                                lockdown.safeSpaceIdentified.value.value
                              )
                            : lockdown.safeSpaceIdentified &&
                              lockdown.safeSpaceIdentified.isNA &&
                              lockdown.safeSpaceIdentified.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.safeSpaceIdentified &&
                          lockdown.safeSpaceIdentified.isNA &&
                          lockdown.safeSpaceIdentified.isNA
                            ? lockdown.safeSpaceIdentified.isNA.comment
                            : lockdown.safeSpaceIdentified &&
                              lockdown.safeSpaceIdentified.comment
                            ? lockdown.safeSpaceIdentified.comment
                            : "-"}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.safeSpaceIdentified &&
                          lockdown.safeSpaceIdentified &&
                          lockdown.safeSpaceIdentified.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  lockdown.safeSpaceIdentified.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Has the lockdown / shelter-in-place plan been
                          rehearsed, and drills conducted with all staff /
                          volunteers?
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.locdownDrillConducted &&
                          lockdown.locdownDrillConducted.value &&
                          lockdown.locdownDrillConducted.isNA &&
                          !lockdown.locdownDrillConducted.isNA.flag
                            ? getScoreFromAnswer(
                                lockdown.locdownDrillConducted.value.value
                              )
                            : lockdown.locdownDrillConducted &&
                              lockdown.locdownDrillConducted.isNA &&
                              lockdown.locdownDrillConducted.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.locdownDrillConducted &&
                          lockdown.locdownDrillConducted.isNA &&
                          lockdown.locdownDrillConducted.isNA
                            ? lockdown.locdownDrillConducted.isNA.comment
                            : lockdown.locdownDrillConducted &&
                              lockdown.locdownDrillConducted.comment
                            ? lockdown.locdownDrillConducted.comment
                            : "-"}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.locdownDrillConducted &&
                          lockdown.locdownDrillConducted.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  lockdown.locdownDrillConducted.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Security Guarding</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Is the Jamatkhana protected by security guards?</td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.protectionSecurityGuard &&
                          securityGuarding.protectionSecurityGuard.value &&
                          securityGuarding.protectionSecurityGuard.isNA &&
                          !securityGuarding.protectionSecurityGuard.isNA.flag
                            ? getScoreFromAnswer(
                                securityGuarding.protectionSecurityGuard.value
                                  .value
                              )
                            : securityGuarding.protectionSecurityGuard &&
                              securityGuarding.protectionSecurityGuard.isNA &&
                              securityGuarding.protectionSecurityGuard.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.protectionSecurityGuard &&
                          securityGuarding.protectionSecurityGuard.isNA &&
                          securityGuarding.protectionSecurityGuard.isNA
                            ? securityGuarding.protectionSecurityGuard.isNA
                                .comment
                            : securityGuarding.protectionSecurityGuard &&
                              securityGuarding.protectionSecurityGuard.comment
                            ? securityGuarding.protectionSecurityGuard.comment
                            : "-"}
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.protectionSecurityGuard &&
                          securityGuarding.protectionSecurityGuard &&
                          securityGuarding.protectionSecurityGuard.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityGuarding.protectionSecurityGuard.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Is performance managed through written SOPs and are
                          they clearly understood and rigidly followed?
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.performanceManagementSystem &&
                          securityGuarding.performanceManagementSystem.value &&
                          securityGuarding.performanceManagementSystem.isNA &&
                          !securityGuarding.performanceManagementSystem.isNA
                            .flag
                            ? getScoreFromAnswer(
                                securityGuarding.performanceManagementSystem
                                  .value.value
                              )
                            : securityGuarding.performanceManagementSystem &&
                              securityGuarding.performanceManagementSystem
                                .isNA &&
                              securityGuarding.performanceManagementSystem.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.performanceManagementSystem &&
                          securityGuarding.performanceManagementSystem.isNA &&
                          securityGuarding.performanceManagementSystem.isNA
                            ? securityGuarding.performanceManagementSystem.isNA
                                .comment
                            : securityGuarding.performanceManagementSystem &&
                              securityGuarding.performanceManagementSystem
                                .comment
                            ? securityGuarding.performanceManagementSystem
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.performanceManagementSystem &&
                          securityGuarding.performanceManagementSystem &&
                          securityGuarding.performanceManagementSystem.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityGuarding.performanceManagementSystem
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Does each guard post have post-orders / assignment
                          instructions that are rigidly followed?
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.guardsInstructions &&
                          securityGuarding.guardsInstructions.value &&
                          securityGuarding.guardsInstructions.isNA &&
                          !securityGuarding.guardsInstructions.isNA.flag
                            ? getScoreFromAnswer(
                                securityGuarding.guardsInstructions.value.value
                              )
                            : securityGuarding.guardsInstructions &&
                              securityGuarding.guardsInstructions.isNA &&
                              securityGuarding.guardsInstructions.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.guardsInstructions &&
                          securityGuarding.guardsInstructions.isNA &&
                          securityGuarding.guardsInstructions.isNA
                            ? securityGuarding.guardsInstructions.isNA.comment
                            : securityGuarding.guardsInstructions &&
                              securityGuarding.guardsInstructions.comment
                            ? securityGuarding.guardsInstructions.comment
                            : "-"}
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.guardsInstructions &&
                          securityGuarding.guardsInstructions &&
                          securityGuarding.guardsInstructions.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityGuarding.guardsInstructions.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>Are there regular reviews of performance?</td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.regularReview &&
                          securityGuarding.regularReview.value &&
                          securityGuarding.regularReview.isNA &&
                          !securityGuarding.regularReview.isNA.flag
                            ? getScoreFromAnswer(
                                securityGuarding.regularReview.value.value
                              )
                            : securityGuarding.regularReview &&
                              securityGuarding.regularReview.isNA &&
                              securityGuarding.regularReview.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.regularReview &&
                          securityGuarding.regularReview.isNA &&
                          securityGuarding.regularReview.isNA
                            ? securityGuarding.regularReview.isNA.comment
                            : securityGuarding.regularReview &&
                              securityGuarding.regularReview.comment
                            ? securityGuarding.regularReview.comment
                            : "-"}
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.regularReview &&
                          securityGuarding.regularReview &&
                          securityGuarding.regularReview.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityGuarding.regularReview.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>Are there an adequate number of guards?</td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.adequateNumberOfGuards &&
                          securityGuarding.adequateNumberOfGuards.value &&
                          securityGuarding.adequateNumberOfGuards.isNA &&
                          !securityGuarding.adequateNumberOfGuards.isNA.flag
                            ? getScoreFromAnswer(
                                securityGuarding.adequateNumberOfGuards.value
                                  .value
                              )
                            : securityGuarding.adequateNumberOfGuards &&
                              securityGuarding.adequateNumberOfGuards.isNA &&
                              securityGuarding.adequateNumberOfGuards.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.adequateNumberOfGuards &&
                          securityGuarding.adequateNumberOfGuards.isNA &&
                          securityGuarding.adequateNumberOfGuards.isNA
                            ? securityGuarding.adequateNumberOfGuards.isNA
                                .comment
                            : securityGuarding.adequateNumberOfGuards &&
                              securityGuarding.adequateNumberOfGuards.comment
                            ? securityGuarding.adequateNumberOfGuards.comment
                            : "-"}
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.adequateNumberOfGuards &&
                          securityGuarding.adequateNumberOfGuards &&
                          securityGuarding.adequateNumberOfGuards.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityGuarding.adequateNumberOfGuards.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>Are the guards logically positioned / deployed?</td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.guardsLogiciallyDeployed &&
                          securityGuarding.guardsLogiciallyDeployed.value &&
                          securityGuarding.guardsLogiciallyDeployed.isNA &&
                          !securityGuarding.guardsLogiciallyDeployed.isNA.flag
                            ? getScoreFromAnswer(
                                securityGuarding.guardsLogiciallyDeployed.value
                                  .value
                              )
                            : securityGuarding.guardsLogiciallyDeployed &&
                              securityGuarding.guardsLogiciallyDeployed.isNA &&
                              securityGuarding.guardsLogiciallyDeployed.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.guardsLogiciallyDeployed &&
                          securityGuarding.guardsLogiciallyDeployed.isNA &&
                          securityGuarding.guardsLogiciallyDeployed.isNA
                            ? securityGuarding.guardsLogiciallyDeployed.isNA
                                .comment
                            : securityGuarding.guardsLogiciallyDeployed &&
                              securityGuarding.guardsLogiciallyDeployed.comment
                            ? securityGuarding.guardsLogiciallyDeployed.comment
                            : "-"}
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.guardsLogiciallyDeployed &&
                          securityGuarding.guardsLogiciallyDeployed &&
                          securityGuarding.guardsLogiciallyDeployed.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityGuarding.guardsLogiciallyDeployed
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Do the quards have communication systems, Ideallv a
                          VAr or other hand-held radio system:
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.guardsHaveCommuncationSystem &&
                          securityGuarding.guardsHaveCommuncationSystem.value &&
                          securityGuarding.guardsHaveCommuncationSystem.isNA &&
                          !securityGuarding.guardsHaveCommuncationSystem.isNA
                            .flag
                            ? getScoreFromAnswer(
                                securityGuarding.guardsHaveCommuncationSystem
                                  .value.value
                              )
                            : securityGuarding.guardsHaveCommuncationSystem &&
                              securityGuarding.guardsHaveCommuncationSystem
                                .isNA &&
                              securityGuarding.guardsHaveCommuncationSystem.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.guardsHaveCommuncationSystem &&
                          securityGuarding.guardsHaveCommuncationSystem.isNA &&
                          securityGuarding.guardsHaveCommuncationSystem.isNA
                            ? securityGuarding.guardsHaveCommuncationSystem.isNA
                                .comment
                            : securityGuarding.guardsHaveCommuncationSystem &&
                              securityGuarding.guardsHaveCommuncationSystem
                                .comment
                            ? securityGuarding.guardsHaveCommuncationSystem
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.guardsHaveCommuncationSystem &&
                          securityGuarding.guardsHaveCommuncationSystem &&
                          securityGuarding.guardsHaveCommuncationSystem
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityGuarding.guardsHaveCommuncationSystem
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Do the guards have suitable and adequate equipment and
                          clothing for the context e.g. a torch, a whistle, a
                          defensive baton, wet-weather clothing?
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.adequateAndEnoughSystem &&
                          securityGuarding.adequateAndEnoughSystem.value &&
                          securityGuarding.adequateAndEnoughSystem.isNA &&
                          !securityGuarding.adequateAndEnoughSystem.isNA.flag
                            ? getScoreFromAnswer(
                                securityGuarding.adequateAndEnoughSystem.value
                                  .value
                              )
                            : securityGuarding.adequateAndEnoughSystem &&
                              securityGuarding.adequateAndEnoughSystem.isNA &&
                              securityGuarding.adequateAndEnoughSystem.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.adequateAndEnoughSystem &&
                          securityGuarding.adequateAndEnoughSystem.isNA &&
                          securityGuarding.adequateAndEnoughSystem.isNA
                            ? securityGuarding.adequateAndEnoughSystem.isNA
                                .comment
                            : securityGuarding.adequateAndEnoughSystem &&
                              securityGuarding.adequateAndEnoughSystem.comment
                            ? securityGuarding.adequateAndEnoughSystem.comment
                            : "-"}
                        </td>
                        <td>
                          {securityGuarding &&
                          securityGuarding.adequateAndEnoughSystem &&
                          securityGuarding.adequateAndEnoughSystem &&
                          securityGuarding.adequateAndEnoughSystem.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityGuarding.adequateAndEnoughSystem.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Electronic Security</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Does a functional and adequate (lV/ camera svstem
                          exist to monitor the perimeter.
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.adequteCameraSystemPerimeter &&
                          electronicSecurity.adequteCameraSystemPerimeter
                            .value &&
                          electronicSecurity.adequteCameraSystemPerimeter
                            .isNA &&
                          !electronicSecurity.adequteCameraSystemPerimeter.isNA
                            .flag
                            ? getScoreFromAnswer(
                                electronicSecurity.adequteCameraSystemPerimeter
                                  .value.value
                              )
                            : electronicSecurity.adequteCameraSystemPerimeter &&
                              electronicSecurity.adequteCameraSystemPerimeter
                                .isNA &&
                              electronicSecurity.adequteCameraSystemPerimeter
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.adequteCameraSystemPerimeter &&
                          electronicSecurity.adequteCameraSystemPerimeter
                            .isNA &&
                          electronicSecurity.adequteCameraSystemPerimeter.isNA
                            ? electronicSecurity.adequteCameraSystemPerimeter
                                .isNA.comment
                            : electronicSecurity.adequteCameraSystemPerimeter &&
                              electronicSecurity.adequteCameraSystemPerimeter
                                .comment
                            ? electronicSecurity.adequteCameraSystemPerimeter
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.adequteCameraSystemPerimeter &&
                          electronicSecurity.adequteCameraSystemPerimeter &&
                          electronicSecurity.adequteCameraSystemPerimeter
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  electronicSecurity
                                    .adequteCameraSystemPerimeter.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does a functional and adequate CCTV / camera system
                          exist to monitor the access control points!
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.adequteCameraSystemAccessControlPoint &&
                          electronicSecurity
                            .adequteCameraSystemAccessControlPoint.value &&
                          electronicSecurity
                            .adequteCameraSystemAccessControlPoint.isNA &&
                          !electronicSecurity
                            .adequteCameraSystemAccessControlPoint.isNA.flag
                            ? getScoreFromAnswer(
                                electronicSecurity
                                  .adequteCameraSystemAccessControlPoint.value
                                  .value
                              )
                            : electronicSecurity.adequteCameraSystemAccessControlPoint &&
                              electronicSecurity
                                .adequteCameraSystemAccessControlPoint.isNA &&
                              electronicSecurity
                                .adequteCameraSystemAccessControlPoint.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.adequteCameraSystemAccessControlPoint &&
                          electronicSecurity
                            .adequteCameraSystemAccessControlPoint.isNA &&
                          electronicSecurity
                            .adequteCameraSystemAccessControlPoint.isNA
                            ? electronicSecurity
                                .adequteCameraSystemAccessControlPoint.isNA
                                .comment
                            : electronicSecurity.adequteCameraSystemAccessControlPoint &&
                              electronicSecurity
                                .adequteCameraSystemAccessControlPoint.comment
                            ? electronicSecurity
                                .adequteCameraSystemAccessControlPoint.comment
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.adequteCameraSystemAccessControlPoint &&
                          electronicSecurity.adequteCameraSystemAccessControlPoint &&
                          electronicSecurity
                            .adequteCameraSystemAccessControlPoint.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  electronicSecurity
                                    .adequteCameraSystemAccessControlPoint.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does a functional and adequate CCTV / camera system
                          exist to monitor the interior of the compound?
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.adequteCameraSystemInterior &&
                          electronicSecurity.adequteCameraSystemInterior
                            .value &&
                          electronicSecurity.adequteCameraSystemInterior.isNA &&
                          !electronicSecurity.adequteCameraSystemInterior.isNA
                            .flag
                            ? getScoreFromAnswer(
                                electronicSecurity.adequteCameraSystemInterior
                                  .value.value
                              )
                            : electronicSecurity.adequteCameraSystemInterior &&
                              electronicSecurity.adequteCameraSystemInterior
                                .isNA &&
                              electronicSecurity.adequteCameraSystemInterior
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.adequteCameraSystemInterior &&
                          electronicSecurity.adequteCameraSystemInterior.isNA &&
                          electronicSecurity.adequteCameraSystemInterior.isNA
                            ? electronicSecurity.adequteCameraSystemInterior
                                .isNA.comment
                            : electronicSecurity.adequteCameraSystemInterior &&
                              electronicSecurity.adequteCameraSystemInterior
                                .comment
                            ? electronicSecurity.adequteCameraSystemInterior
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.adequteCameraSystemInterior &&
                          electronicSecurity.adequteCameraSystemInterior &&
                          electronicSecurity.adequteCameraSystemInterior
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  electronicSecurity.adequteCameraSystemInterior
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does a functional and adequate CCTV / camera svstem
                          exist to monitor recon and noice areas.
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.adequteCameraSystemNoiseArea &&
                          electronicSecurity.adequteCameraSystemNoiseArea
                            .value &&
                          electronicSecurity.adequteCameraSystemNoiseArea
                            .isNA &&
                          !electronicSecurity.adequteCameraSystemNoiseArea.isNA
                            .flag
                            ? getScoreFromAnswer(
                                electronicSecurity.adequteCameraSystemNoiseArea
                                  .value.value
                              )
                            : electronicSecurity.adequteCameraSystemNoiseArea &&
                              electronicSecurity.adequteCameraSystemNoiseArea
                                .isNA &&
                              electronicSecurity.adequteCameraSystemNoiseArea
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.adequteCameraSystemNoiseArea &&
                          electronicSecurity.adequteCameraSystemNoiseArea
                            .isNA &&
                          electronicSecurity.adequteCameraSystemNoiseArea.isNA
                            ? electronicSecurity.adequteCameraSystemNoiseArea
                                .isNA.comment
                            : electronicSecurity.adequteCameraSystemNoiseArea &&
                              electronicSecurity.adequteCameraSystemNoiseArea
                                .comment
                            ? electronicSecurity.adequteCameraSystemNoiseArea
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.adequteCameraSystemNoiseArea &&
                          electronicSecurity.adequteCameraSystemNoiseArea &&
                          electronicSecurity.adequteCameraSystemNoiseArea
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  electronicSecurity
                                    .adequteCameraSystemNoiseArea.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Is the CCTV system monitored from a dedicated and
                          suitable control room!
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.dedicatedMonitoringRoom &&
                          electronicSecurity.dedicatedMonitoringRoom.value &&
                          electronicSecurity.dedicatedMonitoringRoom.isNA &&
                          !electronicSecurity.dedicatedMonitoringRoom.isNA.flag
                            ? getScoreFromAnswer(
                                electronicSecurity.dedicatedMonitoringRoom.value
                                  .value
                              )
                            : electronicSecurity.dedicatedMonitoringRoom &&
                              electronicSecurity.dedicatedMonitoringRoom.isNA &&
                              electronicSecurity.dedicatedMonitoringRoom.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.dedicatedMonitoringRoom &&
                          electronicSecurity.dedicatedMonitoringRoom.isNA &&
                          electronicSecurity.dedicatedMonitoringRoom.isNA
                            ? electronicSecurity.dedicatedMonitoringRoom.isNA
                                .comment
                            : electronicSecurity.dedicatedMonitoringRoom &&
                              electronicSecurity.dedicatedMonitoringRoom.comment
                            ? electronicSecurity.dedicatedMonitoringRoom.comment
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.dedicatedMonitoringRoom &&
                          electronicSecurity.dedicatedMonitoringRoom &&
                          electronicSecurity.dedicatedMonitoringRoom.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  electronicSecurity.dedicatedMonitoringRoom
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Is the CCTV system actively monitored, 24/7?</td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.cctvActivelyMonitored &&
                          electronicSecurity.cctvActivelyMonitored.value &&
                          electronicSecurity.cctvActivelyMonitored.isNA &&
                          !electronicSecurity.cctvActivelyMonitored.isNA.flag
                            ? getScoreFromAnswer(
                                electronicSecurity.cctvActivelyMonitored.value
                                  .value
                              )
                            : electronicSecurity.cctvActivelyMonitored &&
                              electronicSecurity.cctvActivelyMonitored.isNA &&
                              electronicSecurity.cctvActivelyMonitored.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.cctvActivelyMonitored &&
                          electronicSecurity.cctvActivelyMonitored.isNA &&
                          electronicSecurity.cctvActivelyMonitored.isNA
                            ? electronicSecurity.cctvActivelyMonitored.isNA
                                .comment
                            : electronicSecurity.cctvActivelyMonitored &&
                              electronicSecurity.cctvActivelyMonitored.comment
                            ? electronicSecurity.cctvActivelyMonitored.comment
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.cctvActivelyMonitored &&
                          electronicSecurity.cctvActivelyMonitored &&
                          electronicSecurity.cctvActivelyMonitored.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  electronicSecurity.cctvActivelyMonitored.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Is the CCTV date recorder and storen for at past 30
                          days?
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.dateRecordedForAtLeast30Days &&
                          electronicSecurity.dateRecordedForAtLeast30Days
                            .value &&
                          electronicSecurity.dateRecordedForAtLeast30Days
                            .isNA &&
                          !electronicSecurity.dateRecordedForAtLeast30Days.isNA
                            .flag
                            ? getScoreFromAnswer(
                                electronicSecurity.dateRecordedForAtLeast30Days
                                  .value.value
                              )
                            : electronicSecurity.dateRecordedForAtLeast30Days &&
                              electronicSecurity.dateRecordedForAtLeast30Days
                                .isNA &&
                              electronicSecurity.dateRecordedForAtLeast30Days
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.dateRecordedForAtLeast30Days &&
                          electronicSecurity.dateRecordedForAtLeast30Days
                            .isNA &&
                          electronicSecurity.dateRecordedForAtLeast30Days.isNA
                            ? electronicSecurity.dateRecordedForAtLeast30Days
                                .isNA.comment
                            : electronicSecurity.dateRecordedForAtLeast30Days &&
                              electronicSecurity.dateRecordedForAtLeast30Days
                                .comment
                            ? electronicSecurity.dateRecordedForAtLeast30Days
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.dateRecordedForAtLeast30Days &&
                          electronicSecurity.dateRecordedForAtLeast30Days &&
                          electronicSecurity.dateRecordedForAtLeast30Days
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  electronicSecurity
                                    .dateRecordedForAtLeast30Days.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the perimeter have a functional intruder
                          detection sustem?
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.intrduerDetectionSystem &&
                          electronicSecurity.intrduerDetectionSystem.value &&
                          electronicSecurity.intrduerDetectionSystem.isNA &&
                          !electronicSecurity.intrduerDetectionSystem.isNA.flag
                            ? getScoreFromAnswer(
                                electronicSecurity.intrduerDetectionSystem.value
                                  .value
                              )
                            : electronicSecurity.intrduerDetectionSystem &&
                              electronicSecurity.intrduerDetectionSystem.isNA &&
                              electronicSecurity.intrduerDetectionSystem.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.intrduerDetectionSystem &&
                          electronicSecurity.intrduerDetectionSystem.isNA &&
                          electronicSecurity.intrduerDetectionSystem.isNA
                            ? electronicSecurity.intrduerDetectionSystem.isNA
                                .comment
                            : electronicSecurity.intrduerDetectionSystem &&
                              electronicSecurity.intrduerDetectionSystem.comment
                            ? electronicSecurity.intrduerDetectionSystem.comment
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.intrduerDetectionSystem &&
                          electronicSecurity.intrduerDetectionSystem &&
                          electronicSecurity.intrduerDetectionSystem.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  electronicSecurity.intrduerDetectionSystem
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Do the doors and windows have functional intrudel-
                          detection systems?
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.doorsAndWindowIntruderSystem &&
                          electronicSecurity.doorsAndWindowIntruderSystem
                            .value &&
                          electronicSecurity.doorsAndWindowIntruderSystem
                            .isNA &&
                          !electronicSecurity.doorsAndWindowIntruderSystem.isNA
                            .flag
                            ? getScoreFromAnswer(
                                electronicSecurity.doorsAndWindowIntruderSystem
                                  .value.value
                              )
                            : electronicSecurity.doorsAndWindowIntruderSystem &&
                              electronicSecurity.doorsAndWindowIntruderSystem
                                .isNA &&
                              electronicSecurity.doorsAndWindowIntruderSystem
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.doorsAndWindowIntruderSystem &&
                          electronicSecurity.doorsAndWindowIntruderSystem
                            .isNA &&
                          electronicSecurity.doorsAndWindowIntruderSystem.isNA
                            ? electronicSecurity.doorsAndWindowIntruderSystem
                                .isNA.comment
                            : electronicSecurity.doorsAndWindowIntruderSystem &&
                              electronicSecurity.doorsAndWindowIntruderSystem
                                .comment
                            ? electronicSecurity.doorsAndWindowIntruderSystem
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.doorsAndWindowIntruderSystem &&
                          electronicSecurity.doorsAndWindowIntruderSystem &&
                          electronicSecurity.doorsAndWindowIntruderSystem
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  electronicSecurity
                                    .doorsAndWindowIntruderSystem.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the Jamatkhana have a functional public Address
                          (PA) svstem?
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.functionalPublicAddressSystem &&
                          electronicSecurity.functionalPublicAddressSystem
                            .value &&
                          electronicSecurity.functionalPublicAddressSystem
                            .isNA &&
                          !electronicSecurity.functionalPublicAddressSystem.isNA
                            .flag
                            ? getScoreFromAnswer(
                                electronicSecurity.functionalPublicAddressSystem
                                  .value.value
                              )
                            : electronicSecurity.functionalPublicAddressSystem &&
                              electronicSecurity.functionalPublicAddressSystem
                                .isNA &&
                              electronicSecurity.functionalPublicAddressSystem
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.functionalPublicAddressSystem &&
                          electronicSecurity.functionalPublicAddressSystem
                            .isNA &&
                          electronicSecurity.functionalPublicAddressSystem.isNA
                            ? electronicSecurity.functionalPublicAddressSystem
                                .isNA.comment
                            : electronicSecurity.functionalPublicAddressSystem &&
                              electronicSecurity.functionalPublicAddressSystem
                                .comment
                            ? electronicSecurity.functionalPublicAddressSystem
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {electronicSecurity &&
                          electronicSecurity.functionalPublicAddressSystem &&
                          electronicSecurity.functionalPublicAddressSystem &&
                          electronicSecurity.functionalPublicAddressSystem
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  electronicSecurity
                                    .functionalPublicAddressSystem.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Safety Response</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          Are an adequate number of functioning and maintained
                          smoke detectors fitted throughout the Jamatkhana?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.smokeDetector &&
                          safety_response.smokeDetector.value &&
                          safety_response.smokeDetector.isNA &&
                          !safety_response.smokeDetector.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.smokeDetector.value.value
                              )
                            : safety_response.smokeDetector &&
                              safety_response.smokeDetector.isNA &&
                              safety_response.smokeDetector.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.smokeDetector &&
                          safety_response.smokeDetector.isNA &&
                          safety_response.smokeDetector.isNA
                            ? safety_response.smokeDetector.isNA.comment
                            : safety_response.smokeDetector &&
                              safety_response.smokeDetector.comment
                            ? safety_response.smokeDetector.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.smokeDetector &&
                          safety_response.smokeDetector &&
                          safety_response.smokeDetector.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.smokeDetector.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are an adequate number of functioning and maintained
                          fire extinguishers fitted throughout the Jamatkhana?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fireExtinguisher &&
                          safety_response.fireExtinguisher.value &&
                          safety_response.fireExtinguisher.isNA &&
                          !safety_response.fireExtinguisher.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.fireExtinguisher.value.value
                              )
                            : safety_response.fireExtinguisher &&
                              safety_response.fireExtinguisher.isNA &&
                              safety_response.fireExtinguisher.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fireExtinguisher &&
                          safety_response.fireExtinguisher.isNA &&
                          safety_response.fireExtinguisher.isNA
                            ? safety_response.fireExtinguisher.isNA.comment
                            : safety_response.fireExtinguisher &&
                              safety_response.fireExtinguisher.comment
                            ? safety_response.fireExtinguisher.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fireExtinguisher &&
                          safety_response.fireExtinguisher &&
                          safety_response.fireExtinguisher.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.fireExtinguisher.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are an adequate number of functioning and maintained
                          fire hoses fitted throughout the Jamatkhana?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fireHouses &&
                          safety_response.fireHouses.value &&
                          safety_response.fireHouses.isNA &&
                          !safety_response.fireHouses.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.fireHouses.value.value
                              )
                            : safety_response.fireHouses &&
                              safety_response.fireHouses.isNA &&
                              safety_response.fireHouses.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fireHouses &&
                          safety_response.fireHouses.isNA &&
                          safety_response.fireHouses.isNA
                            ? safety_response.fireHouses.isNA.comment
                            : safety_response.fireHouses &&
                              safety_response.fireHouses.comment
                            ? safety_response.fireHouses.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fireHouses &&
                          safety_response.fireHouses &&
                          safety_response.fireHouses.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.fireHouses.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are an adequate number of functioning and maintained
                          sprinklers, connected to a sprinkler system, fitted
                          throughout the Jamatkhana?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.sprinklerSystem &&
                          safety_response.sprinklerSystem.value &&
                          safety_response.sprinklerSystem.isNA &&
                          !safety_response.sprinklerSystem.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.sprinklerSystem.value.value
                              )
                            : safety_response.sprinklerSystem &&
                              safety_response.sprinklerSystem.isNA &&
                              safety_response.sprinklerSystem.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.sprinklerSystem &&
                          safety_response.sprinklerSystem.isNA &&
                          safety_response.sprinklerSystem.isNA
                            ? safety_response.sprinklerSystem.isNA.comment
                            : safety_response.sprinklerSystem &&
                              safety_response.sprinklerSystem.comment
                            ? safety_response.sprinklerSystem.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.sprinklerSystem &&
                          safety_response.sprinklerSystem &&
                          safety_response.sprinklerSystem.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.sprinklerSystem.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Is there an adequate amount of relevant emergency
                          signage on display throughout the Jamatkhana?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.emergencySingage &&
                          safety_response.emergencySingage.value &&
                          safety_response.emergencySingage.isNA &&
                          !safety_response.emergencySingage.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.emergencySingage.value.value
                              )
                            : safety_response.emergencySingage &&
                              safety_response.emergencySingage.isNA &&
                              safety_response.emergencySingage.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.emergencySingage &&
                          safety_response.emergencySingage.isNA &&
                          safety_response.emergencySingage.isNA
                            ? safety_response.emergencySingage.isNA.comment
                            : safety_response.emergencySingage &&
                              safety_response.emergencySingage.comment
                            ? safety_response.emergencySingage.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.emergencySingage &&
                          safety_response.emergencySingage &&
                          safety_response.emergencySingage.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.emergencySingage.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the Jamatkhana have internal fire doors i.e.
                          doors specifically designed to slow/stop the spread of
                          fire?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.internalFireDoor &&
                          safety_response.internalFireDoor.value &&
                          safety_response.internalFireDoor.isNA &&
                          !safety_response.internalFireDoor.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.internalFireDoor.value.value
                              )
                            : safety_response.internalFireDoor &&
                              safety_response.internalFireDoor.isNA &&
                              safety_response.internalFireDoor.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.internalFireDoor &&
                          safety_response.internalFireDoor.isNA &&
                          safety_response.internalFireDoor.isNA
                            ? safety_response.internalFireDoor.isNA.comment
                            : safety_response.internalFireDoor &&
                              safety_response.internalFireDoor.comment
                            ? safety_response.internalFireDoor.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.internalFireDoor &&
                          safety_response.internalFireDoor &&
                          safety_response.internalFireDoor.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.internalFireDoor.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are an adequate number of functioning, unblocked and
                          clearly signposted emergency exits, located throughout
                          the premises?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.signedPostEmergencyExit &&
                          safety_response.signedPostEmergencyExit.value &&
                          safety_response.signedPostEmergencyExit.isNA &&
                          !safety_response.signedPostEmergencyExit.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.signedPostEmergencyExit.value
                                  .value
                              )
                            : safety_response.signedPostEmergencyExit &&
                              safety_response.signedPostEmergencyExit.isNA &&
                              safety_response.signedPostEmergencyExit.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.signedPostEmergencyExit &&
                          safety_response.signedPostEmergencyExit.isNA &&
                          safety_response.signedPostEmergencyExit.isNA
                            ? safety_response.signedPostEmergencyExit.isNA
                                .comment
                            : safety_response.signedPostEmergencyExit &&
                              safety_response.signedPostEmergencyExit.comment
                            ? safety_response.signedPostEmergencyExit.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.signedPostEmergencyExit &&
                          safety_response.signedPostEmergencyExit &&
                          safety_response.signedPostEmergencyExit.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.signedPostEmergencyExit.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are evacuation routes and assembly areas clear and
                          signposted?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.clearEvacuationRoutes &&
                          safety_response.clearEvacuationRoutes.value &&
                          safety_response.clearEvacuationRoutes.isNA &&
                          !safety_response.clearEvacuationRoutes.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.clearEvacuationRoutes.value
                                  .value
                              )
                            : safety_response.clearEvacuationRoutes &&
                              safety_response.clearEvacuationRoutes.isNA &&
                              safety_response.clearEvacuationRoutes.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.clearEvacuationRoutes &&
                          safety_response.clearEvacuationRoutes.isNA &&
                          safety_response.clearEvacuationRoutes.isNA
                            ? safety_response.clearEvacuationRoutes.isNA.comment
                            : safety_response.clearEvacuationRoutes &&
                              safety_response.clearEvacuationRoutes.comment
                            ? safety_response.clearEvacuationRoutes.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.clearEvacuationRoutes &&
                          safety_response.clearEvacuationRoutes &&
                          safety_response.clearEvacuationRoutes.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.clearEvacuationRoutes.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are emergency exits fitted with push-bars / panic-bars
                          (possibly alarmed), to facilitate a rapid exit from
                          the Jamatkhana?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.emergencyExitWithPushBar &&
                          safety_response.emergencyExitWithPushBar.value &&
                          safety_response.emergencyExitWithPushBar.isNA &&
                          !safety_response.emergencyExitWithPushBar.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.emergencyExitWithPushBar.value
                                  .value
                              )
                            : safety_response.emergencyExitWithPushBar &&
                              safety_response.emergencyExitWithPushBar.isNA &&
                              safety_response.emergencyExitWithPushBar.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.emergencyExitWithPushBar &&
                          safety_response.emergencyExitWithPushBar.isNA &&
                          safety_response.emergencyExitWithPushBar.isNA
                            ? safety_response.emergencyExitWithPushBar.isNA
                                .comment
                            : safety_response.emergencyExitWithPushBar &&
                              safety_response.emergencyExitWithPushBar.comment
                            ? safety_response.emergencyExitWithPushBar.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.emergencyExitWithPushBar &&
                          safety_response.emergencyExitWithPushBar &&
                          safety_response.emergencyExitWithPushBar.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.emergencyExitWithPushBar.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are the Jamatkhana stairways safe and fitted with
                          handrails?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fittedWithHandRails &&
                          safety_response.fittedWithHandRails.value &&
                          safety_response.fittedWithHandRails.isNA &&
                          !safety_response.fittedWithHandRails.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.fittedWithHandRails.value.value
                              )
                            : safety_response.fittedWithHandRails &&
                              safety_response.fittedWithHandRails.isNA &&
                              safety_response.fittedWithHandRails.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fittedWithHandRails &&
                          safety_response.fittedWithHandRails.isNA &&
                          safety_response.fittedWithHandRails.isNA
                            ? safety_response.fittedWithHandRails.isNA.comment
                            : safety_response.fittedWithHandRails &&
                              safety_response.fittedWithHandRails.comment
                            ? safety_response.fittedWithHandRails.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fittedWithHandRails &&
                          safety_response.fittedWithHandRails &&
                          safety_response.fittedWithHandRails.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.fittedWithHandRails.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Is the Jamatkhana supported by a government or private
                          fire brigade / firefighting service with suitable
                          capacity to support during a fire?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.supportedByGovermentOrPrivateFireBrigade &&
                          safety_response
                            .supportedByGovermentOrPrivateFireBrigade.value &&
                          safety_response
                            .supportedByGovermentOrPrivateFireBrigade.isNA &&
                          !safety_response
                            .supportedByGovermentOrPrivateFireBrigade.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response
                                  .supportedByGovermentOrPrivateFireBrigade
                                  .value.value
                              )
                            : safety_response.fittedWithHandRails &&
                              safety_response
                                .supportedByGovermentOrPrivateFireBrigade
                                .isNA &&
                              safety_response
                                .supportedByGovermentOrPrivateFireBrigade.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.supportedByGovermentOrPrivateFireBrigade &&
                          safety_response
                            .supportedByGovermentOrPrivateFireBrigade.isNA &&
                          safety_response
                            .supportedByGovermentOrPrivateFireBrigade.isNA
                            ? safety_response
                                .supportedByGovermentOrPrivateFireBrigade.isNA
                                .comment
                            : safety_response.supportedByGovermentOrPrivateFireBrigade &&
                              safety_response
                                .supportedByGovermentOrPrivateFireBrigade
                                .comment
                            ? safety_response
                                .supportedByGovermentOrPrivateFireBrigade
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.supportedByGovermentOrPrivateFireBrigade &&
                          safety_response.supportedByGovermentOrPrivateFireBrigade &&
                          safety_response
                            .supportedByGovermentOrPrivateFireBrigade.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .supportedByGovermentOrPrivateFireBrigade
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Is the response time of the fire brigade /
                          firefighting service adequate i.e. within 10 minutes?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fireBridageWIthInMinutes &&
                          safety_response.fireBridageWIthInMinutes.value &&
                          safety_response.fireBridageWIthInMinutes.isNA &&
                          !safety_response.fireBridageWIthInMinutes.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.fireBridageWIthInMinutes.value
                                  .value
                              )
                            : safety_response.fireBridageWIthInMinutes &&
                              safety_response.fireBridageWIthInMinutes.isNA &&
                              safety_response.fireBridageWIthInMinutes.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fireBridageWIthInMinutes &&
                          safety_response.fireBridageWIthInMinutes.isNA &&
                          safety_response.fireBridageWIthInMinutes.isNA
                            ? safety_response.fireBridageWIthInMinutes.isNA
                                .comment
                            : safety_response.fireBridageWIthInMinutes &&
                              safety_response.fireBridageWIthInMinutes.comment
                            ? safety_response.fireBridageWIthInMinutes.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fireBridageWIthInMinutes &&
                          safety_response.fireBridageWIthInMinutes &&
                          safety_response.fireBridageWIthInMinutes.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.fireBridageWIthInMinutes.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the Jamatkhana have adequate onsite water supply
                          and reservoirs?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.adequateWaterSupply &&
                          safety_response.adequateWaterSupply.value &&
                          safety_response.adequateWaterSupply.isNA &&
                          !safety_response.adequateWaterSupply.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.adequateWaterSupply.value.value
                              )
                            : safety_response.adequateWaterSupply &&
                              safety_response.adequateWaterSupply.isNA &&
                              safety_response.adequateWaterSupply.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.adequateWaterSupply &&
                          safety_response.adequateWaterSupply.isNA &&
                          safety_response.adequateWaterSupply.isNA
                            ? safety_response.adequateWaterSupply.isNA.comment
                            : safety_response.adequateWaterSupply &&
                              safety_response.adequateWaterSupply.comment
                            ? safety_response.adequateWaterSupply.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.adequateWaterSupply &&
                          safety_response.adequateWaterSupply &&
                          safety_response.adequateWaterSupply.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.adequateWaterSupply.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Is there an on-site medical response available at the
                          Jamatkhana (e.g. trauma pack, stretcher and
                          defibrillator)?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.onSiteMedicalResponse &&
                          safety_response.onSiteMedicalResponse.value &&
                          safety_response.onSiteMedicalResponse.isNA &&
                          !safety_response.onSiteMedicalResponse.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.onSiteMedicalResponse.value
                                  .value
                              )
                            : safety_response.onSiteMedicalResponse &&
                              safety_response.onSiteMedicalResponse.isNA &&
                              safety_response.onSiteMedicalResponse.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.onSiteMedicalResponse &&
                          safety_response.onSiteMedicalResponse.isNA &&
                          safety_response.onSiteMedicalResponse.isNA
                            ? safety_response.onSiteMedicalResponse.isNA.comment
                            : safety_response.onSiteMedicalResponse &&
                              safety_response.onSiteMedicalResponse.comment
                            ? safety_response.onSiteMedicalResponse.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.onSiteMedicalResponse &&
                          safety_response.onSiteMedicalResponse &&
                          safety_response.onSiteMedicalResponse.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.onSiteMedicalResponse.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Is the Jamatkhana supported by an ambulance service
                          with suitable capacity?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.amulanceService &&
                          safety_response.amulanceService.value &&
                          safety_response.amulanceService.isNA &&
                          !safety_response.amulanceService.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.amulanceService.value.value
                              )
                            : safety_response.amulanceService &&
                              safety_response.amulanceService.isNA &&
                              safety_response.amulanceService.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.amulanceService &&
                          safety_response.amulanceService.isNA &&
                          safety_response.amulanceService.isNA
                            ? safety_response.amulanceService.isNA.comment
                            : safety_response.amulanceService &&
                              safety_response.amulanceService.comment
                            ? safety_response.amulanceService.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.amulanceService &&
                          safety_response.amulanceService &&
                          safety_response.amulanceService.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.amulanceService.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Is the Jamatkhana close to a hospital with suitable
                          capacity to manage multiple casualties?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.closeByHospital &&
                          safety_response.closeByHospital.value &&
                          safety_response.closeByHospital.isNA &&
                          !safety_response.closeByHospital.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.closeByHospital.value.value
                              )
                            : safety_response.closeByHospital &&
                              safety_response.closeByHospital.isNA &&
                              safety_response.closeByHospital.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.closeByHospital &&
                          safety_response.closeByHospital.isNA &&
                          safety_response.closeByHospital.isNA
                            ? safety_response.closeByHospital.isNA.comment
                            : safety_response.closeByHospital &&
                              safety_response.closeByHospital.comment
                            ? safety_response.closeByHospital.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.closeByHospital &&
                          safety_response.closeByHospital &&
                          safety_response.closeByHospital.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.closeByHospital.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Is the Jamatkhana supported by a law enforcement
                          service with suitable capacity?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.lawEnforcementService &&
                          safety_response.lawEnforcementService.value &&
                          safety_response.lawEnforcementService.isNA &&
                          !safety_response.lawEnforcementService.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.lawEnforcementService.value
                                  .value
                              )
                            : safety_response.lawEnforcementService &&
                              safety_response.lawEnforcementService.isNA &&
                              safety_response.lawEnforcementService.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.lawEnforcementService &&
                          safety_response.lawEnforcementService.isNA &&
                          safety_response.lawEnforcementService.isNA
                            ? safety_response.lawEnforcementService.isNA.comment
                            : safety_response.lawEnforcementService &&
                              safety_response.lawEnforcementService.comment
                            ? safety_response.lawEnforcementService.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.lawEnforcementService &&
                          safety_response.lawEnforcementService &&
                          safety_response.lawEnforcementService.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.lawEnforcementService.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Is cooking onsite permitted and well managed?</td>
                        <td>
                          {safety_response &&
                          safety_response.cookingOnSite &&
                          safety_response.cookingOnSite.value &&
                          safety_response.cookingOnSite.isNA &&
                          !safety_response.cookingOnSite.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.cookingOnSite.value.value
                              )
                            : safety_response.cookingOnSite &&
                              safety_response.cookingOnSite.isNA &&
                              safety_response.cookingOnSite.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.cookingOnSite &&
                          safety_response.cookingOnSite.isNA &&
                          safety_response.cookingOnSite.isNA
                            ? safety_response.cookingOnSite.isNA.comment
                            : safety_response.cookingOnSite &&
                              safety_response.cookingOnSite.comment
                            ? safety_response.cookingOnSite.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.cookingOnSite &&
                          safety_response.cookingOnSite &&
                          safety_response.cookingOnSite.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.cookingOnSite.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          If Hazardous Material (HAZMAT) e.g. fuel, oils,
                          chemicals are stored onsite, are they well managed, in
                          accordance with regulations?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.harzardElementWellManaged &&
                          safety_response.harzardElementWellManaged.value &&
                          safety_response.harzardElementWellManaged.isNA &&
                          !safety_response.harzardElementWellManaged.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.harzardElementWellManaged.value
                                  .value
                              )
                            : safety_response.harzardElementWellManaged &&
                              safety_response.harzardElementWellManaged.isNA &&
                              safety_response.harzardElementWellManaged.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.harzardElementWellManaged &&
                          safety_response.harzardElementWellManaged.isNA &&
                          safety_response.harzardElementWellManaged.isNA
                            ? safety_response.harzardElementWellManaged.isNA
                                .comment
                            : safety_response.harzardElementWellManaged &&
                              safety_response.harzardElementWellManaged.comment
                            ? safety_response.harzardElementWellManaged.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.harzardElementWellManaged &&
                          safety_response.harzardElementWellManaged &&
                          safety_response.harzardElementWellManaged.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.harzardElementWellManaged
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the location have a back-up power supply, capable
                          of supporting the essential systems?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.backupPowerSupply &&
                          safety_response.backupPowerSupply.value &&
                          safety_response.backupPowerSupply.isNA &&
                          !safety_response.backupPowerSupply.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.backupPowerSupply.value.value
                              )
                            : safety_response.backupPowerSupply &&
                              safety_response.backupPowerSupply.isNA &&
                              safety_response.backupPowerSupply.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.backupPowerSupply &&
                          safety_response.backupPowerSupply.isNA &&
                          safety_response.backupPowerSupply.isNA
                            ? safety_response.backupPowerSupply.isNA.comment
                            : safety_response.backupPowerSupply &&
                              safety_response.backupPowerSupply.comment
                            ? safety_response.backupPowerSupply.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.backupPowerSupply &&
                          safety_response.backupPowerSupply &&
                          safety_response.backupPowerSupply.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.backupPowerSupply.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the location have disabled persons accessibility
                          that is suitable for emergency situations (e.g.
                          ramps)?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.disabledPersonAccess &&
                          safety_response.disabledPersonAccess.value &&
                          safety_response.disabledPersonAccess.isNA &&
                          !safety_response.disabledPersonAccess.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.disabledPersonAccess.value.value
                              )
                            : safety_response.disabledPersonAccess &&
                              safety_response.disabledPersonAccess.isNA &&
                              safety_response.disabledPersonAccess.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.disabledPersonAccess &&
                          safety_response.disabledPersonAccess.isNA &&
                          safety_response.disabledPersonAccess.isNA
                            ? safety_response.disabledPersonAccess.isNA.comment
                            : safety_response.disabledPersonAccess &&
                              safety_response.disabledPersonAccess.comment
                            ? safety_response.disabledPersonAccess.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.disabledPersonAccess &&
                          safety_response.disabledPersonAccess &&
                          safety_response.disabledPersonAccess.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.disabledPersonAccess.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Has staff/volunteer training, rehearsals and drills
                          been carried out in the last 12 months for reaction to
                          a safety related incident e.g. fire, medical emergency
                          or earthquake?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.staffTrainingRehearsal &&
                          safety_response.staffTrainingRehearsal.value &&
                          safety_response.staffTrainingRehearsal.isNA &&
                          !safety_response.staffTrainingRehearsal.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.staffTrainingRehearsal.value
                                  .value
                              )
                            : safety_response.staffTrainingRehearsal &&
                              safety_response.staffTrainingRehearsal.isNA &&
                              safety_response.staffTrainingRehearsal.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.staffTrainingRehearsal &&
                          safety_response.staffTrainingRehearsal.isNA &&
                          safety_response.staffTrainingRehearsal.isNA
                            ? safety_response.staffTrainingRehearsal.isNA
                                .comment
                            : safety_response.staffTrainingRehearsal &&
                              safety_response.staffTrainingRehearsal.comment
                            ? safety_response.staffTrainingRehearsal.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.staffTrainingRehearsal &&
                          safety_response.staffTrainingRehearsal &&
                          safety_response.staffTrainingRehearsal.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.staffTrainingRehearsal.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are regular hazard / safety inspections carried out
                          throughout the Jamatkhana?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.safetyInspection &&
                          safety_response.safetyInspection.value &&
                          safety_response.safetyInspection.isNA &&
                          !safety_response.safetyInspection.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.safetyInspection.value.value
                              )
                            : safety_response.safetyInspection &&
                              safety_response.safetyInspection.isNA &&
                              safety_response.safetyInspection.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.safetyInspection &&
                          safety_response.safetyInspection.isNA &&
                          safety_response.safetyInspection.isNA
                            ? safety_response.safetyInspection.isNA.comment
                            : safety_response.safetyInspection &&
                              safety_response.safetyInspection.comment
                            ? safety_response.safetyInspection.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.safetyInspection &&
                          safety_response.safetyInspection &&
                          safety_response.safetyInspection.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.safetyInspection.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are regular hazard / safety inspections carried out
                          throughout the Jamatkhana?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fireProcedureDocumented &&
                          safety_response.fireProcedureDocumented.value &&
                          safety_response.fireProcedureDocumented.isNA &&
                          !safety_response.fireProcedureDocumented.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.fireProcedureDocumented.value
                                  .value
                              )
                            : safety_response.fireProcedureDocumented &&
                              safety_response.fireProcedureDocumented.isNA &&
                              safety_response.fireProcedureDocumented.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fireProcedureDocumented &&
                          safety_response.fireProcedureDocumented.isNA &&
                          safety_response.fireProcedureDocumented.isNA
                            ? safety_response.fireProcedureDocumented.isNA
                                .comment
                            : safety_response.fireProcedureDocumented &&
                              safety_response.fireProcedureDocumented.comment
                            ? safety_response.fireProcedureDocumented.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fireProcedureDocumented &&
                          safety_response.fireProcedureDocumented &&
                          safety_response.fireProcedureDocumented.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.fireProcedureDocumented.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Are all fire related procedures documented?</td>
                        <td>
                          {safety_response &&
                          safety_response.fireProcedureDocumented25 &&
                          safety_response.fireProcedureDocumented25.value &&
                          safety_response.fireProcedureDocumented25.isNA &&
                          !safety_response.fireProcedureDocumented25.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.fireProcedureDocumented25.value
                                  .value
                              )
                            : safety_response.fireProcedureDocumented25 &&
                              safety_response.fireProcedureDocumented25.isNA &&
                              safety_response.fireProcedureDocumented25.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fireProcedureDocumented25 &&
                          safety_response.fireProcedureDocumented25.isNA &&
                          safety_response.fireProcedureDocumented25.isNA
                            ? safety_response.fireProcedureDocumented25.isNA
                                .comment
                            : safety_response.fireProcedureDocumented25 &&
                              safety_response.fireProcedureDocumented25.comment
                            ? safety_response.fireProcedureDocumented25.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fireProcedureDocumented25 &&
                          safety_response.fireProcedureDocumented25 &&
                          safety_response.fireProcedureDocumented25.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.fireProcedureDocumented25
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the Jamatkhana have a medical emergency plan?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.medicalEmergencyPlan &&
                          safety_response.medicalEmergencyPlan.value &&
                          safety_response.medicalEmergencyPlan.isNA &&
                          !safety_response.medicalEmergencyPlan.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.medicalEmergencyPlan.value.value
                              )
                            : safety_response.medicalEmergencyPlan &&
                              safety_response.medicalEmergencyPlan.isNA &&
                              safety_response.medicalEmergencyPlan.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.medicalEmergencyPlan &&
                          safety_response.medicalEmergencyPlan.isNA &&
                          safety_response.medicalEmergencyPlan.isNA
                            ? safety_response.medicalEmergencyPlan.isNA.comment
                            : safety_response.medicalEmergencyPlan &&
                              safety_response.medicalEmergencyPlan.comment
                            ? safety_response.medicalEmergencyPlan.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.medicalEmergencyPlan &&
                          safety_response.medicalEmergencyPlan &&
                          safety_response.medicalEmergencyPlan.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.medicalEmergencyPlan.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the Jamatkhana have a medical emergency plan?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.medicalEmergencySystem &&
                          safety_response.medicalEmergencySystem.value &&
                          safety_response.medicalEmergencySystem.isNA &&
                          !safety_response.medicalEmergencySystem.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.medicalEmergencySystem.value
                                  .value
                              )
                            : safety_response.medicalEmergencySystem &&
                              safety_response.medicalEmergencySystem.isNA &&
                              safety_response.medicalEmergencySystem.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.medicalEmergencySystem &&
                          safety_response.medicalEmergencySystem.isNA &&
                          safety_response.medicalEmergencySystem.isNA
                            ? safety_response.medicalEmergencySystem.isNA
                                .comment
                            : safety_response.medicalEmergencySystem &&
                              safety_response.medicalEmergencySystem.comment
                            ? safety_response.medicalEmergencySystem.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.medicalEmergencySystem &&
                          safety_response.medicalEmergencySystem &&
                          safety_response.medicalEmergencySystem.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.medicalEmergencySystem.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          What is the overall level of staff/volunteer
                          experience with regards to office safety and security?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.staffVolunteerExperience &&
                          safety_response.staffVolunteerExperience.value &&
                          safety_response.staffVolunteerExperience.isNA &&
                          !safety_response.staffVolunteerExperience.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.staffVolunteerExperience.value
                                  .value
                              )
                            : safety_response.staffVolunteerExperience &&
                              safety_response.staffVolunteerExperience.isNA &&
                              safety_response.staffVolunteerExperience.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.staffVolunteerExperience &&
                          safety_response.staffVolunteerExperience.isNA &&
                          safety_response.staffVolunteerExperience.isNA
                            ? safety_response.staffVolunteerExperience.isNA
                                .comment
                            : safety_response.staffVolunteerExperience &&
                              safety_response.staffVolunteerExperience.comment
                            ? safety_response.staffVolunteerExperience.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.staffVolunteerExperience &&
                          safety_response.staffVolunteerExperience &&
                          safety_response.staffVolunteerExperience.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.staffVolunteerExperience.image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          What is the overall level of staff/volunteer
                          understanding with regards to their safety and
                          security responsibilities?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.staffVolunteerUnderstanding &&
                          safety_response.staffVolunteerUnderstanding.value &&
                          safety_response.staffVolunteerUnderstanding &&
                          safety_response.staffVolunteerUnderstanding.isNA &&
                          !safety_response.staffVolunteerUnderstanding.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.staffVolunteerUnderstanding
                                  .value.value
                              )
                            : safety_response.staffVolunteerUnderstanding &&
                              safety_response.staffVolunteerUnderstanding
                                .isNA &&
                              safety_response.staffVolunteerUnderstanding.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.staffVolunteerUnderstanding &&
                          safety_response.staffVolunteerUnderstanding.isNA &&
                          safety_response.staffVolunteerUnderstanding.isNA
                            ? safety_response.staffVolunteerUnderstanding.isNA
                                .comment
                            : safety_response.staffVolunteerUnderstanding &&
                              safety_response.staffVolunteerUnderstanding
                                .comment
                            ? safety_response.staffVolunteerUnderstanding
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.staffVolunteerUnderstanding &&
                          safety_response.staffVolunteerUnderstanding &&
                          safety_response.staffVolunteerUnderstanding.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.staffVolunteerUnderstanding
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are all internal hallways clear of obstructions?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.hallwayClearOfObsuruction &&
                          safety_response.hallwayClearOfObsuruction.value &&
                          safety_response.hallwayClearOfObsuruction.isNA &&
                          !safety_response.hallwayClearOfObsuruction.isNA.flag
                            ? getScoreFromAnswer(
                                safety_response.hallwayClearOfObsuruction.value
                                  .value
                              )
                            : safety_response.hallwayClearOfObsuruction &&
                              safety_response.hallwayClearOfObsuruction.isNA &&
                              safety_response.hallwayClearOfObsuruction.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.hallwayClearOfObsuruction &&
                          safety_response.hallwayClearOfObsuruction.isNA &&
                          safety_response.hallwayClearOfObsuruction.isNA
                            ? safety_response.hallwayClearOfObsuruction.isNA
                                .comment
                            : safety_response.hallwayClearOfObsuruction &&
                              safety_response.hallwayClearOfObsuruction.comment
                            ? safety_response.hallwayClearOfObsuruction.comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.hallwayClearOfObsuruction &&
                          safety_response.hallwayClearOfObsuruction &&
                          safety_response.hallwayClearOfObsuruction.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.hallwayClearOfObsuruction
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are the Jamatkhana elevators serviced and functioning?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.jamatkhanaServicedFunctioning &&
                          safety_response.jamatkhanaServicedFunctioning.value &&
                          safety_response.jamatkhanaServicedFunctioning.isNA &&
                          !safety_response.jamatkhanaServicedFunctioning.isNA
                            .flag
                            ? getScoreFromAnswer(
                                safety_response.jamatkhanaServicedFunctioning
                                  .value.value
                              )
                            : safety_response.jamatkhanaServicedFunctioning &&
                              safety_response.jamatkhanaServicedFunctioning
                                .isNA &&
                              safety_response.jamatkhanaServicedFunctioning.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.jamatkhanaServicedFunctioning &&
                          safety_response.jamatkhanaServicedFunctioning.isNA &&
                          safety_response.jamatkhanaServicedFunctioning.isNA
                            ? safety_response.jamatkhanaServicedFunctioning.isNA
                                .comment
                            : safety_response.jamatkhanaServicedFunctioning &&
                              safety_response.jamatkhanaServicedFunctioning
                                .comment
                            ? safety_response.jamatkhanaServicedFunctioning
                                .comment
                            : "-"}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.jamatkhanaServicedFunctioning &&
                          safety_response.jamatkhanaServicedFunctioning &&
                          safety_response.jamatkhanaServicedFunctioning
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.jamatkhanaServicedFunctioning
                                    .image
                                ),
                              }}
                            ></span>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12}>
          <Card>
            <Card.Header>
              <Card.Title>Risk Register</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xl={12}>
                  <table className="w-full colored-table summary-table">
                    <thead>
                      <tr>
                        <th>Assesment Group</th>
                        <th>Additional Control Measure</th>
                        <th>Control Measure Category</th>
                        <th>Control Measure Details</th>
                        <th>Responsible Person</th>
                        <th>Deadline</th>
                      </tr>
                    </thead>
                    <tbody>
                      {riskRegister && riskRegister.length > 0 ? (
                        riskRegister.map((risk, index) => (
                          <tr key={`risk_register_${index}`}>
                            <td>{risk.section}</td>
                            <td>{risk.additionalControlMeasure}</td>
                            <td>
                              {risk.controlMeasureCateory
                                ? risk.controlMeasureCateory.value
                                : ""}
                            </td>
                            <td>{risk.implementationSteps}</td>
                            <td>{risk.responsiblePerson}</td>
                            <td>{risk.deadline}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6}>No Data Available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
